import { useEnvContext } from "context/EnvContext";
import { APP_CAREPROVIDER, TRACK_EVENTS } from "core/consts";
import { isAppEmbedded } from "core/model/utils/featureFlags";
import { Z_INDEX_CRISP, margin, sizing } from "ds_legacy/materials/metrics";
import { useMedia } from "dsl/atoms/ResponsiveMedia";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { useTracking } from "react-tracking";

const CrispContext = createContext<{
  triggerCrispEvent: (event: "close" | "hide" | "open" | "show") => void;
}>({
  triggerCrispEvent(event: "close" | "hide" | "open" | "show") {
    console.log(event);
  },
});

export function useCrispContext() {
  return useContext(CrispContext);
}

export default function CrispFrame({ children }: { children: ReactNode }) {
  const { trackEvent } = useTracking();
  const crispRef = useRef<HTMLIFrameElement>(null);
  const { app } = useEnvContext();
  const { isMobile } = useMedia();
  const location = useLocation();
  const [shouldHide, setShouldHide] = useState<boolean>(
    app === APP_CAREPROVIDER &&
      isMobile &&
      !location.pathname.includes("/careprovider/auth"),
  );

  function triggerCrispEvent(event: "close" | "hide" | "open" | "show") {
    if (!crispRef.current) return;
    const crisp = crispRef.current.contentWindow?.$crisp;

    crisp?.push(["do", `chat:${event}`]);
  }

  function handleMessageEvents(event: MessageEvent) {
    if (
      event.origin === window.location.origin &&
      typeof event.data === "string" &&
      event.data.includes("CRISP") &&
      crispRef.current
    ) {
      switch (event.data) {
        case "CRISP:BUTTON_CLICKED": {
          trackEvent({ name: TRACK_EVENTS.CRISP_ICON_CLICKED });
          break;
        }
        case "CRISP:CLOSE": {
          const wasClosed = crispRef.current.style.opacity === "0";
          crispRef.current.style.visibility = "hidden";
          crispRef.current.style.opacity = "0";
          crispRef.current.style.width = sizing(10);
          crispRef.current.style.height = sizing(10);
          crispRef.current.style.margin = margin(0, 1, 2, 1);
          setTimeout(
            () => {
              if (crispRef.current)
                crispRef.current.style.visibility = "visible";
            },
            wasClosed ? 0 : 200,
          );
          setTimeout(
            () => {
              if (crispRef.current) crispRef.current.style.opacity = "1";
            },
            wasClosed ? 0 : 300,
          );
          if (shouldHide) {
            triggerCrispEvent("hide");
          }
          break;
        }
        case "CRISP:OPEN": {
          crispRef.current.style.visibility = "visible";
          crispRef.current.style.width = "100vw";
          crispRef.current.style.height = "100vh";
          crispRef.current.style.margin = margin(0);
          crispRef.current.style.opacity = "1";
          break;
        }
        default:
          return;
      }
    }
  }

  useEffect(() => {
    setShouldHide(
      app === APP_CAREPROVIDER &&
        isMobile &&
        !location.pathname.includes("/careprovider/auth"),
    );
  }, [app, isMobile, location]);

  useEffect(() => {
    window.addEventListener("message", handleMessageEvents, false);

    const iframeWindow = crispRef.current?.contentWindow;
    if (iframeWindow) {
      const isOpened = iframeWindow.$crisp?.is?.("chat:opened");

      if (shouldHide && !isOpened) {
        triggerCrispEvent("hide");
      } else {
        triggerCrispEvent("show");
      }
    }

    return () => {
      window.removeEventListener("message", handleMessageEvents);
    };
  }, [crispRef, shouldHide]);

  return (
    <>
      <CrispContext.Provider value={{ triggerCrispEvent }}>
        {children}
      </CrispContext.Provider>
      {!isAppEmbedded() && (
        <iframe
          id="crisp_sandbox"
          ref={crispRef}
          title="crisp_sandbox"
          style={{
            visibility: "hidden",
            margin: margin(0),
            borderStyle: "none",
            position: "fixed",
            bottom: 0,
            left: 0,
            zIndex: Z_INDEX_CRISP,
            opacity: 0,
            transition: "opacity .15s ease-in-out",
          }}
          srcDoc={`<div><script src="/crisp.js" origin="${window.location.origin}"></script></div>`}
        ></iframe>
      )}
    </>
  );
}
