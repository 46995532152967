import MaterialTableCell from "@mui/material/TableCell";
import MaterialTableHead from "@mui/material/TableHead";
import MaterialTableRow from "@mui/material/TableRow";
import withStyles from '@mui/styles/withStyles';
import { CARD_BORDER } from "ds_legacy/materials/colors";
import React from "react";

const rowStyles = {
  row: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: CARD_BORDER,
    },
  },
};

const cellStyles = {
  cell: {
    border: "none",
  },
};

const CustomTableCell = withStyles(cellStyles)(
  ({ classes, isHeader, ...props }: any) => (
    <MaterialTableCell className={!isHeader ? classes.cell : ""} {...props} />
  ),
);

const CustomTableRow = withStyles(rowStyles)(
  ({ classes, isClickable, styleOverride, ...props }: any) => (
    <MaterialTableRow
      style={styleOverride}
      className={isClickable ? classes.row : ""}
      {...props}
    />
  ),
);

export const TableCell = (props: any) => <CustomTableCell {...props} />;

export const TableRow = (props: any) => <CustomTableRow {...props} />;

export const TableHead = ({ children }: { children: React.ReactNode }) => (
  <MaterialTableHead>
    <MaterialTableRow>{children}</MaterialTableRow>
  </MaterialTableHead>
);
