import { border, margin } from "ds_legacy/materials/metrics";
import lazy from "dsl/atoms/retry";
import { Suspense } from "react";
import { ConnectedDateSelectorProps } from ".";

const LazyDate = lazy(() => import("./index"));

export function Filler({
  label,
  required,
  width = "150px",
}: {
  label?: string;
  required?: boolean;
  width?: string;
}) {
  return (
    <div
      style={{
        width,
        height: "61px",
        margin: margin(1, 2, 0, 2),
        fontStyle: "14px",
        color: "#767676",
        lineHeight: "19px",
        fontFamily: "Lato",
        fontSize: "1rem",
      }}
    >
      <div style={{ height: "15px", width }} />
      {label && (
        <div
          style={{
            width,
            padding: "4px 0px 2px 0px",
            fontSize: "14px",
            color: "#767676",
            lineHeight: "19px",
            fontFamily: "Lato",
            borderBottom: border({ color: "rgba(0, 0, 0, 0.42)" }),
          }}
        >{`${label} ${required ? "*" : ""}`}</div>
      )}

      <div style={{ height: "20px", width }} />
    </div>
  );
}

export default function LazyPicker(props: ConnectedDateSelectorProps) {
  return (
    <Suspense fallback={<Filler {...props} />}>
      <LazyDate {...props} />
    </Suspense>
  );
}
