import { fromJS } from "immutable";
import Translations from "translations/types";
import { getImmutPath } from "./StatePathHelpers";
import { getJavascriptEntity } from "./StateValueHelpers";

export function isValid(validation: any) {
  if (typeof validation === "boolean") return validation;
  else if (validation == null) return true;
  else if (
    typeof validation === "object" &&
    typeof validation.infos === "object" &&
    validation.infos !== null &&
    Object.keys(validation.infos).length === 0
  )
    return true;

  return validation.infos === true || validation.infos == null;
}

export function getErrorText(validation: any) {
  if (validation?.infos && typeof validation.infos === "string")
    return validation.infos;
  return null;
}

export function getGenericErrorText(
  validation: any,
  translations: Translations,
) {
  const valid = isValid(validation);
  const errorText = getErrorText(validation) || translations.general.required;
  return valid ? undefined : errorText;
}

function setErrorForFieldAt(infos: any, statePath: string, err: unknown) {
  if (statePath === "" || infos == null || typeof infos !== "object") {
    return infos;
  }
  return fromJS(infos).setIn(getImmutPath(statePath), err).toJS();
}

function removeErrorForFieldAt(infos: any, statePath: string) {
  return setErrorForFieldAt(infos, statePath, null);
}

export class Validation {
  infos;

  constructor(infos = {}) {
    this.infos = infos;
  }

  setErrorForFieldAt(statePath: string, err: unknown) {
    return new Validation(setErrorForFieldAt(this.infos, statePath, err));
  }

  removeErrorForFieldAt(statePath: string) {
    return new Validation(removeErrorForFieldAt(this.infos, statePath));
  }

  getErrorForFieldAt(statePath: string) {
    if (statePath === "") return getJavascriptEntity(this.infos);

    if (typeof this.infos === "object") {
      const valuePath = getImmutPath(statePath);
      return valuePath.reduce((red: any, value: any) => {
        if (red !== undefined && red !== null && typeof red === "object") {
          return red[value];
        }
        return red;
      }, this.infos);
    }
    return null;
  }

  getNestedValidation(statePath: string) {
    if (statePath === "") {
      return this;
    }
    return new Validation(this.getErrorForFieldAt(statePath));
  }
}
