import {
  CARESEEKER_STATUS_ACTIVE,
  CARESEEKER_STATUS_CREATED,
  CARESEEKER_STATUS_INACTIVE,
  CARESEEKER_STATUS_LOST,
  CARESEEKER_STATUS_ONBOARDING,
} from "core/consts";
import { ACCENT_COLOR, ERROR_COLOR, SUCCESS_COLOR } from "ds_legacy/materials/colors";

export function getCareseekerStatusColor(status: number | undefined) {
  switch (status) {
    case CARESEEKER_STATUS_ACTIVE:
      return SUCCESS_COLOR;
    case CARESEEKER_STATUS_INACTIVE:
    case CARESEEKER_STATUS_LOST:
      return ERROR_COLOR;
    case CARESEEKER_STATUS_ONBOARDING:
      return ACCENT_COLOR;
    case CARESEEKER_STATUS_CREATED:
      return null;
    default:
      return null;
  }
}

export function onPremHttpRequest({
  body,
  method,
  on_premise_authorization_token,
  on_premise_domain_ssl,
  path,
}: {
  body?: BodyInit;
  method: string;
  on_premise_authorization_token: string | undefined;
  on_premise_domain_ssl: string | undefined;
  path: string;
}) {
  const headers: HeadersInit = {};

  if (on_premise_authorization_token) {
    // username is empty.
    const auth = btoa(`:${on_premise_authorization_token}`);
    headers["Authorization"] = `Basic ${auth}`;
  }

  return fetch(`https://${on_premise_domain_ssl}/${path}`, {
    method,
    headers,
    body,
  });
}
