import {
  ACCOUNT_TYPE_STAFF,
  CREATE_PATIENT_ROLES,
  CREATE_PATIENT_ROLE_SEARCH_TYPE_MAP,
} from "core/consts";
import { Account, Careseeker, CareseekerActions } from "core/types";
import React, { useContext } from "react";
import { useLoggedCareseeker } from "reduxentities/selectors/hooks";

const CareseekerActionsContext = React.createContext<
  Partial<CareseekerActions>
>({});

export const useCareseekerActions = () => useContext(CareseekerActionsContext);

export function getCreatePatients(
  account: Account,
  loggedCareseeker: Careseeker | undefined,
) {
  if (!loggedCareseeker) return false;

  const careseekerRoles = account.roles?.careseeker_roles;
  if (!careseekerRoles) return false;

  const careseekerRolesForCurrentCareseeker = careseekerRoles?.find(
    ({ careseeker }) => careseeker?.id === loggedCareseeker.id,
  );
  if (!careseekerRolesForCurrentCareseeker) return false;

  const roles = careseekerRolesForCurrentCareseeker?.roles;
  if (!roles) return false;

  const create_patients = roles?.some((role) => {
    const hasCreatePatientRole = CREATE_PATIENT_ROLES.includes(
      role as (typeof CREATE_PATIENT_ROLES)[number],
    );
    const searchType =
      CREATE_PATIENT_ROLE_SEARCH_TYPE_MAP?.[
        role as (typeof CREATE_PATIENT_ROLES)[number]
      ];
    const careseekerHasPatientType =
      searchType && loggedCareseeker.patient_types?.includes(searchType);

    return hasCreatePatientRole && careseekerHasPatientType;
  });

  return create_patients;
}

export default function CareseekerActionsProvider({
  account,
  children,
}: {
  account: Account;
  children: React.ReactNode;
}) {
  const loggedCareseeker = useLoggedCareseeker();
  const create_patients = getCreatePatients(account, loggedCareseeker);
  const recareStaff = account?.account_type === ACCOUNT_TYPE_STAFF;

  return (
    <CareseekerActionsContext.Provider
      value={{
        create_patients,
        show_drafts: recareStaff || create_patients,
      }}
    >
      {children}
    </CareseekerActionsContext.Provider>
  );
}
