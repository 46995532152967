import {
  PAGES_FETCHED_COUNTER,
  SCROLL_ITEM_ID_POSITION,
  SCROLL_RESTORATION_TIMESTAMP,
} from "core/consts";
import { useCallback, useEffect, useState } from "react";

export const usePagesFetched = () => {
  const [pagesFetchedCounter] = useState<number>(
    Number(sessionStorage.getItem(PAGES_FETCHED_COUNTER)) ?? 0,
  );

  const incrementPagesFetchedCounter = useCallback(() => {
    const fetchedPages = sessionStorage.getItem(PAGES_FETCHED_COUNTER);
    sessionStorage.setItem(
      PAGES_FETCHED_COUNTER,
      `${Number(fetchedPages) + 1}`,
    );
  }, []);

  const removePersistedData = useCallback(() => {
    const sessionStorageItems = [
      SCROLL_ITEM_ID_POSITION,
      SCROLL_RESTORATION_TIMESTAMP,
    ];
    sessionStorageItems.forEach((key) => sessionStorage.removeItem(key));
  }, []);

  const flushPersistedData = useCallback(() => {
    sessionStorage.removeItem(PAGES_FETCHED_COUNTER);
    removePersistedData();
  }, []);

  useEffect(() => {
    window.addEventListener("beforeunload", flushPersistedData);
    return () => {
      window.removeEventListener("beforeunload", flushPersistedData);
    };
  }, []);

  useEffect(() => {
    if (!pagesFetchedCounter)
      sessionStorage.setItem(PAGES_FETCHED_COUNTER, "0");
  }, []);

  return {
    incrementPagesFetchedCounter,
    pagesFetchedCounter,
    removePersistedData,
    flushPersistedData,
  };
};
