import {
  Checkbox,
  FormControl,
  FormControlProps,
  FormGroup,
  FormHelperTextProps,
  FormLabelProps,
} from "@mui/material";
import { getAriaDescribedByValue } from "core/model/utils/strings";
import { Chip, ChipProps } from "ds_legacy/components/Chip";
import {
  margin as computeMargin,
  dp,
  important,
  margin,
  padding,
  sizing,
} from "ds_legacy/materials/metrics";
import { CSSProperties, useState } from "react";
import {
  FieldValidation,
  FormElement,
  FormElementProps,
  FormWatcher,
  isValid,
} from "react-forms-state";
import styled from "styled-components";
import { SubheadingFormLabel } from "../FormComponents/SubheadingFormLabel";
import Tooltip from "../Tooltip";
import { Validation } from "../Validation";

export type ConnectedChipGroupProps = ChipGroupPresenterProps &
  FormElementProps;

export type ChipGroupPresenterProps = {
  ariaDescribedBy?: string;
  chipStyle?: {
    bold?: boolean;
    height?: CSSProperties["height"];
    justifyContent?: CSSProperties["justifyContent"];
    width?: CSSProperties["width"];
  };
  className?: string;
  disabled?: boolean;
  elementName?: string;
  formControlSx?: FormControlProps["sx"];
  formGroupSx?: CSSProperties;
  formHelperTextSx?: FormHelperTextProps["sx"];
  formLabelSx?: FormLabelProps["sx"];
  onChange: (arg: ToType) => void;
  options: Array<ChipOption>;
  required?: boolean;
  row?: boolean;
  sortOrder?: AnyObject;
  squared?: boolean;
  title?: string;
  validation?: FieldValidation;
  value: Array<number> | undefined;
};

export const ChipGroupContainer = styled.div<{
  margin?: CSSProperties["margin"];
}>`
  display: flex;
  justify-content: flex-start;
  flex: 1 1 auto;
  flex-wrap: wrap;
  margin: ${(props) => props.margin || computeMargin(0, 0, 0, 2)};
`;

export function setMultipleValues(
  values: Array<ToType> | ToType,
  selectedValue: ToType,
) {
  if (!values || values.length === 0) return [selectedValue];

  const copy = [...values];
  if (copy && copy.length > 0) {
    if (!copy.includes(selectedValue)) {
      copy.push(selectedValue);
      return copy;
    } else if (copy.includes(selectedValue)) {
      copy.splice(values.indexOf(selectedValue), 1);
      return copy.length > 0 ? copy : null;
    }
  }

  return null;
}

export function getOrderedItems(
  sortOrder: AnyObject | undefined,
  options: Array<ToType>,
) {
  return sortOrder
    ? options.clone().sort((a, b) => {
        const aOrder = sortOrder[a.value] || a.value;
        const bOrder = sortOrder[b.value] || b.value;

        return aOrder - bOrder;
      })
    : options;
}

export type ChipOption = {
  disabled?: boolean;
  name: string;
  tooltipText?: string;
  value: ToType;
};

function getId(elementName: string | undefined, name: string) {
  return `chip-${elementName ? `${elementName}-` : ""}${name
    .replace(/[ :]/g, "")
    .replace("<", "below")}`;
}

const StyledCheckbox = styled(Checkbox)<{ width?: CSSProperties["width"] }>`
  margin: ${margin(0)};
  border-radius: 1;
  width: ${({ width }) => width ?? undefined};

  // needs to be set manually here as MuiCheckbox got a styleOverrides on materials/theme.tsx
  &.MuiCheckbox-root {
    padding: ${padding(0)};
    // required for accessiblity to ensure focus outline is visible
    outline-offset: ${important(dp(1))};
  }

  &:hover {
    background-color: "transparent";
  }
`;

const HoverCheckbox = ({
  ariaDescribedBy,
  chipProps,
  elementName,
  errorTextId,
  id,
  onChange,
  option,
  validation,
  value,
}: {
  ariaDescribedBy?: string;
  chipProps: ChipProps;
  elementName: ChipGroupPresenterProps["elementName"];
  errorTextId: string;
  id: string;
  onChange: ChipGroupPresenterProps["onChange"];
  option: ChipOption;
  validation: ChipGroupPresenterProps["validation"];
  value: ChipGroupPresenterProps["value"];
}) => {
  const [hovered, setHovered] = useState(false);

  return (
    <StyledCheckbox
      id={id}
      checked={chipProps.selected}
      disabled={chipProps.disabled}
      onChange={() => onChange(setMultipleValues(value, option.value))}
      icon={
        <Chip {...chipProps} hovered={hovered}>
          {option.name}
        </Chip>
      }
      checkedIcon={
        <Chip {...chipProps} hovered={hovered}>
          {option.name}
        </Chip>
      }
      focusRipple
      disableTouchRipple
      inputProps={{
        ...{ ["data-testid"]: id },
        "aria-describedby": getAriaDescribedByValue([
          !isValid(validation) && errorTextId,
          ariaDescribedBy,
        ]),
        "aria-invalid": !isValid(validation) ? true : undefined,
      }}
      name={elementName}
      width={chipProps?.width}
      value={option.value}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      required={chipProps.required}
    />
  );
};

export function ChipGroupPresenter({
  ariaDescribedBy,
  chipStyle,
  elementName,
  formControlSx,
  formGroupSx,
  formHelperTextSx,
  formLabelSx,
  onChange,
  options,
  required,
  row,
  sortOrder,
  squared,
  title,
  validation,
  value,
}: ChipGroupPresenterProps) {
  const errorTextId = elementName ? `${elementName}-error-text` : "error-text";
  const hasError = isValid(validation) === false;
  const chipOptions = getOrderedItems(sortOrder, options);

  const getChip = (option: ChipOption) => {
    const isDisabled = option.disabled;
    const isSelected =
      value && Array.isArray(value) && value.length > 0
        ? value.includes(option.value)
        : option.value === value;
    const id = getId(elementName, option.name);

    const chipProps: ChipProps = {
      bold: isSelected,
      className: !isDisabled && isSelected ? "checked" : undefined,
      disabled: isDisabled,
      error: hasError,
      opacity: isDisabled && isSelected ? 0.5 : undefined,
      primary: isSelected,
      selected: isSelected,
      squared,
      required,
      ...chipStyle,
    };

    return (
      <label key={`chip-${option.name}`} htmlFor={id}>
        <Tooltip title={option.tooltipText}>
          <HoverCheckbox
            id={id}
            chipProps={chipProps}
            elementName={elementName}
            ariaDescribedBy={ariaDescribedBy}
            errorTextId={errorTextId}
            onChange={onChange}
            option={option}
            validation={validation}
            value={value}
          />
        </Tooltip>
      </label>
    );
  };

  return (
    <FormWatcher watchPath={elementName ?? ""}>
      {({ watchedValidation }) => {
        const valid = isValid(watchedValidation);
        return (
          <FormControl
            sx={{ ...formControlSx }}
            component="fieldset"
            error={!valid}
          >
            <SubheadingFormLabel
              sx={{ padding: "0", ...formLabelSx }}
              required={required}
              error={hasError}
            >
              {title}
            </SubheadingFormLabel>
            <FormGroup
              row={row}
              sx={{
                margin: computeMargin(1, 0, 0, 2),
                gap: squared ? sizing(1) : sizing(2),
                ...formGroupSx,
              }}
            >
              {chipOptions.map(getChip)}
            </FormGroup>
            <Validation
              id={errorTextId}
              formHelperTextSx={formHelperTextSx}
              hasCustomValidation
              validation={validation}
              elementName={elementName ?? "chip-group"}
            />
          </FormControl>
        );
      }}
    </FormWatcher>
  );
}

const ConnectedChipGroup =
  FormElement()<ConnectedChipGroupProps>(ChipGroupPresenter);
export default ConnectedChipGroup;
