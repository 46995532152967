import { Autocomplete, ListItem, ListItemText, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useCareproviderAccountContext } from "context/CareproviderAccountContext";
import {
  QUERY_PROGRESS_PENDING,
  REQUEST_ACTION_SET_RECEIVER_ASSIGNEE,
} from "core/consts";
import { GetNameOptions, getName } from "core/model/accounts";
import { formatFromNow } from "core/model/utils/dates";
import { useGetOntology } from "core/model/utils/ontologies/hooks";
import { Account, AuctionRequest } from "core/types";
import { SelectOption } from "ds_legacy/components/SelectInput";
import { WHITE } from "ds_legacy/materials/colors";
import { VerticalLayout } from "ds_legacy/materials/layouts";
import {
  dp,
  ellipsis,
  margin,
  responsiveCSS,
  translate,
} from "ds_legacy/materials/metrics";
import { Body, FONT_SIZE_13 } from "ds_legacy/materials/typography";
import ReceiverAssigneeChange from "dsl/ecosystems/PatientAssessment/ReceiverAssigneeChange";
import { useModal, useRequestAction } from "dsl/hooks";
import { useNotification } from "dsl/organisms/NotificationProvider";
import { useCallback, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { useLocale, useTranslations } from "translations";

const nameOptions: GetNameOptions = {
  withSalutation: false,
  withAcademicTitle: false,
};

const LastWorkedOnWrapper = styled(Body)`
  margin: ${margin(1, 2, -1.5, 0)};
  overflow-y: visible;
  ${ellipsis({ maxWidth: dp(300) })}
  ${responsiveCSS({
    mobile: [`margin: ${margin(0)}`, `align-self: center`],
  })}
`;

const SelectLabel = styled(Body)`
  transform: ${translate({ y: 1 })};
  margin: ${margin(0)};
  ${responsiveCSS({
    mobile: [`transform: ${translate({ y: -4 })}`],
  })}
`;

const ReceiverAssigneeWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: ${margin(1, 0, 0, 0)};
`;

const RightOrnamentWrapper = styled(VerticalLayout)`
  justify-content: "flex-start";
  ${responsiveCSS({
    mobile: [
      `justify-content: center`,
      `align-self: flex-start`,
      `margin: ${margin(0, 0, 0, 2.5)}`,
    ],
  })}
`;

const useGetReceiverAccounts = () => {
  const { accounts } = useCareproviderAccountContext();
  const getOntology = useGetOntology();

  if (!accounts?.length) return [];

  const careprovidersSelectOptions: SelectOption[] = accounts.map(
    (account) => ({
      id: account?.id,
      label: getName(account, getOntology, nameOptions),
      value: account?.id,
    }),
  );

  return careprovidersSelectOptions;
};

const useOnCompleted = ({
  initialAssignee,
  setOpen,
}: {
  initialAssignee: Account | null | undefined;
  setOpen: (open: boolean) => void;
}) => {
  const assigneeRef = useRef<Account | null | undefined>(initialAssignee);
  const { loggedAccount } = useCareproviderAccountContext();

  return useCallback(
    (newAssignee: Account | null | undefined) => {
      const oldAssignee = assigneeRef?.current;
      const newAssigneeId = newAssignee?.id;

      const assigneeChanged =
        newAssigneeId &&
        Number(oldAssignee?.id) !== newAssigneeId &&
        newAssigneeId !== loggedAccount?.id;

      if (assigneeChanged) setOpen(true);
      if (assigneeRef?.current != null) assigneeRef.current = initialAssignee;
    },
    [assigneeRef, loggedAccount],
  );
};

export const ResponsibleReceiverSelect = ({
  auctionRequest,
  currentValue,
}: {
  auctionRequest: AuctionRequest;
  currentValue: SelectOption | null;
}) => {
  const translations = useTranslations();
  const notify = useNotification();
  const [open, setOpen] = useModal();
  const options = useGetReceiverAccounts();

  const [selectedAssignee, setSelectedAssignee] = useState<SelectOption>(
    currentValue as SelectOption,
  );

  const [setAssignee, queryProgress] = useRequestAction({
    actionType: REQUEST_ACTION_SET_RECEIVER_ASSIGNEE,
    auctionRequest,
  });

  const onCompleted = useOnCompleted({
    initialAssignee: auctionRequest.assignee,
    setOpen,
  });

  const handleChange = useCallback(
    (data: SelectOption) =>
      setAssignee({
        context: {
          assignee_id: data?.id === -1 ? undefined : data?.id,
        },
        onCompleted: (
          setAssigneeReturnType: AuctionRequest | null | undefined,
        ) => {
          onCompleted(setAssigneeReturnType?.assignee);
        },
        onError: () =>
          notify({ message: translations.auctionRequest.uploadError }),
      }),
    [],
  );

  const onlyAssigneeOptionIsSelected =
    options?.length === 1 && options[0]?.id === selectedAssignee?.id;

  return (
    <>
      <ReceiverAssigneeWrapper data-testid="receiver-assignee">
        <SelectLabel light>
          {translations.patient.responsiblePerson}
          {translations.general.colon}
          &nbsp;
        </SelectLabel>
        {onlyAssigneeOptionIsSelected ? (
          <Body fontSize={FONT_SIZE_13} color={WHITE}>
            &nbsp;{selectedAssignee.label}
          </Body>
        ) : (
          <Autocomplete<SelectOption | null, undefined, true, undefined>
            autoHighlight
            classes={makeStyles({
              input: {
                minWidth: `${dp(120)} !important`,
                fontSize: `${FONT_SIZE_13} !important`,
                color: `${WHITE} !important`,
                "&::placeholder": { opacity: `1 !important` },
              },
              inputRoot: {
                padding: "0",
                paddingTop: "0 !important",
                paddingBottom: "0 !important",
                color: `${WHITE} !important`,
              },
              endAdornment: {
                color: `${WHITE} !important`,
                top: "0 !important",
              },
            })()}
            componentsProps={{ paper: { sx: { width: "fit-content" } } }}
            data-testid="assignee-autocomplete"
            defaultValue={currentValue as SelectOption}
            disableClearable
            disabled={
              queryProgress === QUERY_PROGRESS_PENDING ||
              onlyAssigneeOptionIsSelected
            }
            id="assignee-autocomplete"
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            onChange={(e, newValue) => {
              handleChange(newValue);
              setSelectedAssignee(newValue);
            }}
            options={options}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{ ...params.inputProps }}
                InputProps={{ ...params.InputProps, disableUnderline: true }}
                placeholder={translations.patient.menuAssigneePlaceholder}
                hiddenLabel
                variant="standard"
              />
            )}
            renderOption={(props, option) => {
              return (
                <ListItem
                  divider
                  disablePadding
                  data-testid={`option-${option?.id}`}
                  {...props}
                  key={option?.id}
                >
                  <ListItemText primary={option?.label} />
                </ListItem>
              );
            }}
            sx={{
              color: WHITE,
              marginLeft: margin(1),
              minWidth: "max-content",
              "& .MuiAutocomplete-popupIndicator": {
                padding: 0,
                color: "white",
              },
            }}
            value={selectedAssignee}
          />
        )}
      </ReceiverAssigneeWrapper>
      {open && auctionRequest?.assignee ? (
        <ReceiverAssigneeChange
          assigned={auctionRequest.assignee}
          auctionRequestId={auctionRequest.id}
          nextNotification={() => {
            setOpen(false);
          }}
        />
      ) : null}
    </>
  );
};

function RightOrnament({ auctionRequest }: { auctionRequest: AuctionRequest }) {
  const getOntology = useGetOntology();
  const translations = useTranslations();
  const locale = useLocale();
  const lastAction = auctionRequest?.last_action;
  const personResponsible =
    lastAction?.account && getName(lastAction?.account, getOntology);
  const timestamp = lastAction?.timestamp && new Date(lastAction?.timestamp);
  const time = timestamp ? formatFromNow(timestamp, locale) : "";

  const currentValue = useMemo(() => {
    return auctionRequest.assignee?.id
      ? {
          id: auctionRequest.assignee.id,
          value: auctionRequest.assignee.id,
          label: getName(auctionRequest.assignee, getOntology, nameOptions),
        }
      : undefined;
  }, [auctionRequest.assignee?.id, nameOptions]);

  return (
    <RightOrnamentWrapper>
      {time && personResponsible ? (
        <LastWorkedOnWrapper light>
          {translations.auctionRequest.lastWorkedOn({
            time,
            personResponsible,
          })}
        </LastWorkedOnWrapper>
      ) : null}
      {auctionRequest ? (
        <ResponsibleReceiverSelect
          currentValue={currentValue || null}
          auctionRequest={auctionRequest}
        />
      ) : null}
    </RightOrnamentWrapper>
  );
}

export default RightOrnament;
