import { GREY_400 } from "ds_legacy/materials/colors";
import { HorizontalLayout } from "ds_legacy/materials/layouts";
import { dp, padding } from "ds_legacy/materials/metrics";
import {
  Caption,
  FONT_SIZE_20,
  FONT_SIZE_24,
} from "ds_legacy/materials/typography";

export function EmptyV2({
  isMessenger,
  title,
}: {
  isMessenger?: boolean;
  title: string;
}) {
  return (
    <HorizontalLayout
      aligned
      justify="center"
      margin="0 auto"
      minHeight={isMessenger ? dp(60) : dp(0)}
      padding={padding(4, 0)}
      width="100%"
      maxWidth={isMessenger ? dp(220) : "100%"}
    >
      <Caption
        color={GREY_400}
        fontSize={isMessenger ? FONT_SIZE_20 : FONT_SIZE_24}
        whiteSpace="normal"
        textAlign="center"
      >
        {title}
      </Caption>
    </HorizontalLayout>
  );
}
