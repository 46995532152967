import { ApolloClient } from "@apollo/client";
import RenderApp from "containers/Appv4";
import ServiceFactory, { Services } from "containers/ServiceFactory";
import { APP_CAREPROVIDER } from "core/consts";
import { ApolloCacheData } from "core/types";
import { selectAndCheckLoggedCareproviderApollo } from "reduxentities/selectors";
import Routes from "./Router";

export type ReceiverContext = ReturnType<typeof Context>;

const Context = (services: Services) => ({
  app: APP_CAREPROVIDER,
  services,

  checkAuth: (
    careproviderId: string,
    apolloClient: ApolloClient<ApolloCacheData>,
  ) => {
    const careprovider = selectAndCheckLoggedCareproviderApollo(
      services.store.getState(),
      parseInt(careproviderId),
      apolloClient,
    );

    if (careprovider === false) {
      services.logger.info("not authenticated, redirecting...");
    }

    return careprovider === true;
  },

  checkNonAuth: (
    careproviderId: string,
    apolloClient: ApolloClient<ApolloCacheData>,
  ) => {
    const careprovider = selectAndCheckLoggedCareproviderApollo(
      services.store.getState(),
      parseInt(careproviderId),
      apolloClient,
    );

    if (careprovider === true) {
      services.logger.info("authenticated, redirecting...", careprovider);
    }

    return careprovider === false;
  },
});

const services = ServiceFactory("Careprovider");
const context = Context(services);

RenderApp(context, Routes);
