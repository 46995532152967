import { QUERY_PROGRESS_PENDING, QUERY_PROGRESS_SUCCEED } from "core/consts";
import { QueryProgress } from "core/types";
import { CheckCircleIcon } from "ds/icons";
import React from "react";
import Translations from "translations/types";
import RSButton from "./index";

export default function RSButtonSaveProgress({
  disabled,
  onSubmit,
  queryProgress,
  style,
  translations,
}: {
  disabled?: boolean;
  onSubmit: AnyFunction;
  queryProgress: QueryProgress;
  style?: React.CSSProperties;
  translations: Translations;
}) {
  switch (queryProgress) {
    case QUERY_PROGRESS_PENDING:
      return (
        <RSButton
          color="secondary"
          id="saving"
          loading="na"
          onClick={onSubmit}
          style={style}
          variant="contained"
        >
          <span style={{ width: "100%" }}>{translations.actions.saving}</span>
        </RSButton>
      );
    case QUERY_PROGRESS_SUCCEED:
      return (
        <RSButton
          color="secondary"
          id="saved"
          LeftIcon={CheckCircleIcon}
          loading="na"
          onClick={onSubmit}
          style={style}
          variant="contained"
        >
          <span style={{ width: "100%" }}>{translations.actions.saved}</span>
        </RSButton>
      );
    default:
      return (
        <RSButton
          color="secondary"
          disabled={disabled}
          id="save"
          loading="na"
          onClick={onSubmit}
          style={style}
          variant="contained"
        >
          <span style={{ width: "100%" }}>{translations.actions.save}</span>
        </RSButton>
      );
  }
}
