import { QUERY_PROGRESS_FAILED } from "core/consts";
import { useEffect } from "react";
import { FormWatcher, isValid } from "react-forms-state";
import { useTranslations } from "translations";
import { useNotification } from "../NotificationProvider";

type ShowNotificationEvent = (validation: AnyObject) => void;

export const getMessage = (
  validation: AnyObject,
  message: string,
  details?: boolean,
) =>
  details
    ? `${message} : ${JSON.stringify(
        validation?.infos && typeof validation.infos === "object"
          ? Object.keys(validation?.infos)
              .filter((s) => validation.infos[s] != true)
              .join(", ")
          : validation,
      )}`
    : message;

export function ValidationNotification({
  details,
  message,
  onShowNotification,
  validation,
}: {
  details?: boolean;
  message?: string;
  onShowNotification?: ShowNotificationEvent;
  validation: AnyObject;
}) {
  const translations = useTranslations();

  const showNotification = useNotification();
  useEffect(() => {
    if (!isValid(validation)) {
      const text = message || translations.actions.validationError;

      showNotification({
        message: getMessage(validation, text, details),
      });

      if (onShowNotification) {
        onShowNotification(validation);
      }
    }
  }, [validation]);

  return null;
}

export const FormValidationNotification = ({
  details,
  message,
  onShowNotification,
}: {
  details?: boolean;
  message?: string;
  onShowNotification?: ShowNotificationEvent;
}) => (
  <FormWatcher watchPath="">
    {({ validation }: { validation: AnyObject }) => (
      <ValidationNotification
        validation={validation}
        details={details}
        message={message}
        onShowNotification={onShowNotification}
      />
    )}
  </FormWatcher>
);

export function ErrorNotification({
  message,
  queryProgress,
}: {
  message: string;
  queryProgress: string;
}) {
  const showNotification = useNotification();

  useEffect(() => {
    if (queryProgress === QUERY_PROGRESS_FAILED) {
      showNotification({ message });
    }
  }, [queryProgress, message]);

  return null;
}
