import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useNotifyReceiverAssignee } from "apollo/hooks/mutations";
import { TRACK_EVENTS } from "core/consts";
import { getName } from "core/model/accounts";
import { useGetOntology } from "core/model/utils/ontologies/hooks";
import { Account, QueryProgress } from "core/types";
import EncryptedTooltip from "ds_legacy/components/EncryptedTooltip";
import RSButton from "ds_legacy/components/RSButton";
import TextAreaInputField from "ds_legacy/components/TextArea";
import { useNotification } from "dsl/organisms/NotificationProvider";
import React, { useCallback, useState } from "react";
import { useTracking } from "react-tracking";
import { useTranslations } from "translations";
import Translations from "translations/types";

export function ChangeDialog({
  confirm,
  queryProgress,
  setText,
  skip,
  text,
  title,
  translations,
}: {
  confirm: () => void;
  queryProgress: QueryProgress;
  setText: React.Dispatch<React.SetStateAction<string>>;
  skip: () => void;
  text: string;
  title: string;
  translations: Translations;
}) {
  return (
    <Dialog open>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextAreaInputField
          label={translations.patient.newResponsibleMessagePlaceholder}
          value={text}
          fullWidth
          rows={3}
          onChange={setText}
          endAdornment={<EncryptedTooltip activateEncryption={false} />}
        />
      </DialogContent>
      <DialogActions>
        <RSButton
          color="primary"
          id="skip"
          loading="na"
          onClick={skip}
          variant="text"
        >
          {translations.actions.skip}
        </RSButton>
        <RSButton
          color="primary"
          id="notify"
          inverted
          loading={queryProgress}
          onClick={confirm}
          variant="text"
        >
          {translations.actions.notify}
        </RSButton>
      </DialogActions>
    </Dialog>
  );
}

export default function ChangeReceiverAssignee({
  assigned,
  auctionRequestId,
  nextNotification,
}: {
  assigned: Partial<Account> | null | undefined;
  auctionRequestId: number;
  nextNotification?: () => void;
}) {
  const [notifyReceiverAssignee, queryProgress] = useNotifyReceiverAssignee({
    auctionRequestId,
  });
  const notify = useNotification();

  const translations = useTranslations();
  const getOntology = useGetOntology();
  const { trackEvent } = useTracking();

  const skip = useCallback(() => {
    trackEvent({
      name: TRACK_EVENTS.NOTIFY_RECEIVER_ASSIGNEE,
      action: "skip",
    });

    if (nextNotification) nextNotification();
  }, [nextNotification]);

  const [text, setText] = useState("");

  const confirm = useCallback(() => {
    trackEvent({
      name: TRACK_EVENTS.NOTIFY_RECEIVER_ASSIGNEE,
      action: "notify",
    });

    notifyReceiverAssignee({ message: text })
      .catch(() => {
        console.error(`Receiver assignee change failed`);
        notify({
          message: translations.patient.notifySocialWorker.assigneeChangeFailed,
        });
      })
      .finally(() => {
        if (nextNotification) nextNotification();
      });
  }, [notifyReceiverAssignee, text]);

  const assignedName = getName(assigned as Account, getOntology, {
    withSalutation: true,
    withAcademicTitle: true,
  });

  return (
    <ChangeDialog
      title={translations.careproviderApp.responsiblePerson.modalTitle({
        assignedName,
      })}
      text={text}
      setText={setText}
      skip={skip}
      queryProgress={queryProgress}
      confirm={confirm}
      translations={translations}
    />
  );
}
