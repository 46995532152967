import { formatPhoneNumber } from "core/model/utils/contacts";
import { Body, Email } from "ds_legacy/materials/typography";
import { usePrint } from "dsl/atoms/Contexts";
import { useMedia } from "dsl/atoms/ResponsiveMedia";

type PhoneNumberType = {
  phone: string | null | undefined;
  prefix: string;
};

const DesktopPhoneNumber = ({ phone, prefix }: PhoneNumberType) => (
  <Body>
    {prefix}&nbsp;
    {formatPhoneNumber(phone)}
  </Body>
);

const MobilePhoneNumber = ({ phone, prefix }: PhoneNumberType) => (
  <Email href={`tel:${phone}`}>
    {prefix}&nbsp;
    {formatPhoneNumber(phone)}
  </Email>
);

const ResponsivePhoneNumber = (props: PhoneNumberType) => {
  const { isMobile } = useMedia();
  return isMobile ? (
    <MobilePhoneNumber {...props} />
  ) : (
    <DesktopPhoneNumber {...props} />
  );
};

const PhoneNumber = (props: PhoneNumberType) => {
  const print = usePrint();

  if (!props.phone) return null;

  return print ? (
    <DesktopPhoneNumber {...props} />
  ) : (
    <ResponsivePhoneNumber {...props} />
  );
};

export default PhoneNumber;
