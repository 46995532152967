import {
  CARE_SOLUTIONS,
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_OTHER,
  HOME_CARE_SOLUTIONS,
  HOME_CARE_SPECIALIZATIONS,
  HOSPITAL_SOLUTIONS,
  HOSPITAL_SPECIALIZATIONS_DE,
  HOSPITAL_SPECIALIZATIONS_FR,
  MEDICAL_SUPPLIES_SOLUTIONS,
  MOBILE_SOLUTIONS,
  OVERHEIGHT,
  OVERWEIGHT_CARE,
  OVERWEIGHT_HOSPITAL,
  OVERWEIGHT_REHAB,
  REHABILITATION_PAYMENT_OPTION,
  REHABILITATION_SOLUTIONS,
  REHA_SPECIALIZATIONS,
  SEARCH_TYPE_CARE,
  SEARCH_TYPE_HOME_CARE,
  SEARCH_TYPE_HOSPITAL,
  SEARCH_TYPE_MEDICAL_SUPPLIES,
  SEARCH_TYPE_REHABILITATION,
  SEARCH_TYPE_TRANSPORT,
  SOLUTION_SHORT_TERM_STATIC_CARE,
  SOLUTION_STATIC_CARE,
  TRANSPORT_SOLUTIONS,
} from "core/consts";
import { ONTOLOGY_GENDER } from "core/model/utils/ontologies";
import {
  AgeRange,
  Auction,
  CareLevel,
  CareLevelInfos as CareLevelInfosType,
  Careseeker,
  GetOntologyType,
  Interval as MinMaxInterval,
  Patient,
  SearchType,
} from "core/types";
import {
  isValid,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds,
} from "date-fns";
import { FieldsHolderType } from "dsl/organisms/PatientMenu/transform";
import { Iterable } from "immutable";
import Translations from "translations/types";
import { PROVIDER_SEARCH_OVERWEIGHT } from "../providerSearch";
import { getAgeFromDate } from "../utils/dates";
import { getEncryptedValue } from "./encryption";

type RadioValue = {
  label: string;
  value: boolean | number | string | null | undefined;
};

type AgeIntervalType = { max: number | null; min: number | null };

export const PatientTypeSolutionMap: {
  [patientType in SearchType]: Array<number>;
} = {
  [SEARCH_TYPE_CARE]: CARE_SOLUTIONS,
  [SEARCH_TYPE_HOSPITAL]: HOSPITAL_SOLUTIONS,
  [SEARCH_TYPE_REHABILITATION]: REHABILITATION_SOLUTIONS,
  [SEARCH_TYPE_TRANSPORT]: TRANSPORT_SOLUTIONS,
  [SEARCH_TYPE_MEDICAL_SUPPLIES]: MEDICAL_SUPPLIES_SOLUTIONS,
  [SEARCH_TYPE_HOME_CARE]: HOME_CARE_SOLUTIONS,
};

export function showMultipleSpecializations({
  searchType,
}: { searchType?: SearchType } = {}) {
  if (!searchType) return false;
  const show = (
    [SEARCH_TYPE_HOSPITAL, SEARCH_TYPE_REHABILITATION] as SearchType[]
  ).includes(searchType);

  return show;
}

export const getSpecializations = ({
  admin,
  careseeker,
  country,
  searchType,
}: {
  admin?: boolean;
  careseeker?: Careseeker;
  country: string;
  searchType: SearchType;
}) => {
  const HOSPITAL_SPECIALIZATIONS =
    country === "FR"
      ? HOSPITAL_SPECIALIZATIONS_FR
      : HOSPITAL_SPECIALIZATIONS_DE;

  switch (searchType) {
    case SEARCH_TYPE_HOSPITAL:
      if (admin) return HOSPITAL_SPECIALIZATIONS;
      return (
        careseeker?.config?.whitelisted_hospital_specializations ??
        HOSPITAL_SPECIALIZATIONS
      );
    case SEARCH_TYPE_REHABILITATION:
      if (admin) return REHA_SPECIALIZATIONS;
      return (
        careseeker?.config?.whitelisted_reha_specializations ??
        REHA_SPECIALIZATIONS
      );
    case SEARCH_TYPE_HOME_CARE:
      if (admin) return HOME_CARE_SPECIALIZATIONS;
      return (
        careseeker?.config?.whitelisted_home_care_specializations ??
        HOME_CARE_SPECIALIZATIONS
      );
    default:
      return [];
  }
};

export const getSolutionsForPatientType = (searchType: SearchType) =>
  PatientTypeSolutionMap[searchType] || [];

export const AgesCare: { [key: string]: AgeRange } = {
  "2": { name: "<2", min: null, max: 2 },
  "2-6": { name: "2-6", min: 2, max: 6 },
  "7-11": { name: "7-11", min: 7, max: 11 },
  "12-17": { name: "12-17", min: 12, max: 17 },
  "18-29": { name: "18-29", min: 18, max: 29 },
  "30-39": { name: "30-39", min: 30, max: 39 },
  "40-49": { name: "40-49", min: 40, max: 49 },
  "50-54": { name: "50-54", min: 50, max: 54 },
  "55-59": { name: "55-59", min: 55, max: 59 },
  "60-64": { name: "60-64", min: 60, max: 64 },
  "65-69": { name: "65-69", min: 65, max: 69 },
  "70-79": { name: "70-79", min: 70, max: 79 },
  "80-89": { name: "80-89", min: 80, max: 89 },
  "90": { name: ">90", min: 90, max: null },
};

export const AgesHospital: { [key: string]: AgeRange } = {
  "18": { name: "<18", min: null, max: 18 },
  "18-59": { name: "18-59", min: 18, max: 59 },
  "60-64": { name: "60-64", min: 60, max: 64 },
  "65-69": { name: "65-69", min: 65, max: 69 },
  "70-74": { name: "70-74", min: 70, max: 74 },
  "75-79": { name: "75-79", min: 75, max: 79 },
  "80-89": { name: "80-89", min: 80, max: 89 },
  "90": { name: ">90", min: 90, max: null },
};

const filteredEntries: [string, AgeRange][] = Object.entries(AgesCare).filter(
  ([_, value]) => (value.min as number) >= 18,
);

export const AgesProviderSearch: { [key: string]: AgeRange } =
  Object.fromEntries(filteredEntries);

/**
 * Leftover from the protected patient data feature
 * @param patient
 * @returns the patient data hidden/shown depending on the SW's protected patient role
 */
export function useGetPatientData({ patient }: { patient: Patient }): {
  age: number | string | null;
  patientIdentifier: string | null | undefined;
} {
  const patientName = getPatientName(patient);
  const patientIdentifier = patientName || patient.external_id;

  const ageInterval = patient?.profile?.age_interval ?? null;
  const age = patient?.profile?.birth_date?.decrypted
    ? getAgeFromDate(patient.profile.birth_date.decrypted)
    : getAge(ageInterval);

  return { patientIdentifier, age };
}

export function getCareLevelOptions(
  translations: Translations,
  excludedOptions?: Array<CareLevel>,
): Array<RadioValue> {
  const carelevels = [
    { label: "1", value: 1, id: "carelevel-one" },
    { label: "2", value: 2, id: "carelevel-two" },
    { label: "3", value: 3, id: "carelevel-three" },
    { label: "4", value: 4, id: "carelevel-four" },
    { label: "5", value: 5, id: "carelevel-five" },
    { label: translations.general.noneR, value: 0, id: "carelevel-zero" },
    { label: translations.general.unknown, value: -1, id: "carelevel-unknown" },
  ];

  if (excludedOptions?.length) {
    const excludedCarelevels = carelevels.filter(
      (carelevel) => !excludedOptions.includes(carelevel.value as CareLevel),
    );
    return excludedCarelevels;
  }

  return carelevels;
}

export const getSpecializationForPatientType = (
  searchTypes: Array<SearchType>,
  country: string,
): Array<number> | null => {
  if (!searchTypes?.length) return [];

  // some search types can map to the same specialization resulting in duplicates
  const specializationsWithDuplicates = searchTypes
    .map((searchType) =>
      getSpecializations({ searchType, admin: true, country }),
    )
    .reduce(
      (result: Array<number>, current: Array<number> | null) =>
        Array.isArray(current) ? result.concat(current) : result,
      [],
    );

  return specializationsWithDuplicates.filter(
    (specialization, i) =>
      specializationsWithDuplicates.indexOf(specialization) === i,
  );
};

export function getCareLevelValue(
  careLevel: CareLevelInfosType | null | undefined,
  translations: Translations,
): RadioValue {
  const diagnosisCareLevel = careLevel?.level;
  if (typeof diagnosisCareLevel === "number") {
    const careLevelValue = getCareLevelOptions(translations).find(
      (level) => level.value === diagnosisCareLevel,
    );
    if (careLevelValue !== undefined) return careLevelValue;
    return { label: translations.general.unknown, value: null };
  }
  return { label: translations.general.unknown, value: null };
}

export const getAges = (
  patientType?: SearchType | null | undefined,
): Array<AgeRange> => {
  let ageRanges: {
    [key: string]: AgeRange;
  } = {};

  if (patientType === SEARCH_TYPE_HOSPITAL) {
    {
      ageRanges = AgesHospital;
    }
  } else {
    ageRanges = AgesCare;
  }

  return Object.keys(ageRanges)
    .map((key: any) => {
      const range = ageRanges[key];
      return {
        name: range.name,
        min: range.min,
        max: range.max,
      };
    })
    .sort((a: any, b: any) => a.min - b.min);
};

export const getAge = (
  ageInterval: AgeIntervalType | null | undefined,
  patientType?: SearchType | null | undefined,
): string | null => {
  if (ageInterval) {
    const ageRanges = getAges(patientType);
    const range = ageRanges.find(
      (queriedRange) => queriedRange.min === ageInterval.min,
    );
    if (range) {
      return range.name;
    }
    return ageRanges[ageRanges.length - 1].name;
  }
  return null;
};

export const getAgeIntervalFromRangeStr = (
  rangeStr: string | null,
  patientType?: SearchType | null,
): AgeIntervalType | null => {
  if (!rangeStr) return null;
  const ageRange = getAges(patientType).find((a) => a.name === rangeStr) || {
    min: null,
    max: null,
  };
  const { max, min } = ageRange;
  return {
    min,
    max: !min ? max : (max && max + 1) || null,
  };
};

export const findAgeConfig = ({
  range,
  value,
}: {
  range: { [key: string]: AgeRange };
  value: MinMaxInterval;
}): [string, AgeRange | null] => {
  if (!value) return ["", null];
  const config = Object.entries(range).find(([_, { max, min }]) => {
    if (value?.max == max && value?.min == min) {
      return true;
    }
    return false;
  });
  return config ?? ["", null];
};

export const isCareSolution = (solution: number | null) =>
  CARE_SOLUTIONS.some((val) => val === solution);

export const isHospitalSolution = (solution: number | null | undefined) =>
  HOSPITAL_SOLUTIONS.some((val) => val === solution);

export const isRehabSolution = (solution: number | null) =>
  REHABILITATION_SOLUTIONS.some((val) => val === solution);

export const isStaticCare = (solution: number | null) =>
  solution === SOLUTION_STATIC_CARE ||
  solution === SOLUTION_SHORT_TERM_STATIC_CARE;

export const hasStaticCare = (solutions: number[] | null | undefined) =>
  solutions?.some(isStaticCare);

export function getPatientName(patient: Patient | null | undefined) {
  const firstName =
    patient?.profile?.first_name &&
    getEncryptedValue(patient.profile.first_name);
  const lastName =
    patient?.profile?.last_name && getEncryptedValue(patient.profile.last_name);

  return [firstName, lastName].join(" ").trim();
}

export const getGenderIconProps = (
  gender: number | undefined,
  getOntology: GetOntologyType,
): { alt: string; path: string } => {
  switch (gender) {
    case GENDER_MALE:
      return {
        path: "gender_male.svg",
        alt: getOntology({ key: GENDER_MALE, type: ONTOLOGY_GENDER }),
      };
    case GENDER_FEMALE:
      return {
        path: "gender_female.svg",
        alt: getOntology({ key: GENDER_FEMALE, type: ONTOLOGY_GENDER }),
      };
    case GENDER_OTHER:
      return {
        path: "gender_non_binary.svg",
        alt: getOntology({ key: GENDER_OTHER, type: ONTOLOGY_GENDER }),
      };
    default:
      return { path: "", alt: "" };
  }
};

export const isMobilePatient = (solutions: number[] | undefined) => {
  if (!solutions)
    return console.error("No solutions passed to function isMobile");
  return solutions.some((solution) => MOBILE_SOLUTIONS.includes(solution));
};

export const getCostPayerName = ({
  auction,
  fields,
  translations,
}: {
  auction?: Auction | null | undefined;
  fields?: FieldsHolderType | null | undefined;
  translations: Translations;
}) => {
  const payers = auction?.payers;
  const paymentMethod =
    payers?.selected_payment_method || fields?.selected_payment_method;
  const insuranceName = payers?.insurance?.name || fields?.insurance?.name;
  const publicPensionName =
    payers?.public_pension?.name || fields?.public_pension;
  switch (paymentMethod) {
    case REHABILITATION_PAYMENT_OPTION.INSURANCE:
      return insuranceName;
    case REHABILITATION_PAYMENT_OPTION.CIVIL_SERVANTS_AID:
      return translations.patient.patientInformation.civilServantsAid;
    case REHABILITATION_PAYMENT_OPTION.PUBLIC_ACCIDENT_INSURANCE:
      return translations.patient.patientInformation.publicAccidentInsurance;
    case REHABILITATION_PAYMENT_OPTION.PUBLIC_PENSION:
      return publicPensionName;
    case REHABILITATION_PAYMENT_OPTION.SOCIAL_SERVICE_DEPARTMENT:
      return translations.patient.patientInformation.socialServiceDepartment;
    case REHABILITATION_PAYMENT_OPTION.SELF_PAYER:
      return translations.patient.patientInformation.selfPayer;
    case REHABILITATION_PAYMENT_OPTION.OTHER:
      return translations.patient.patientInformation.payersOther;
    default:
      return null;
  }
};

export const isOverweight = (
  patientType: SearchType | undefined,
  isProviderSearch: boolean,
): number => {
  if (isProviderSearch) {
    return PROVIDER_SEARCH_OVERWEIGHT;
  }
  switch (patientType) {
    case SEARCH_TYPE_REHABILITATION:
      return OVERWEIGHT_REHAB;
    case SEARCH_TYPE_HOSPITAL:
      return OVERWEIGHT_HOSPITAL;
    default:
      return OVERWEIGHT_CARE;
  }
};

type WeightRange = {
  max: number;
  min: number;
  name: string;
};

type WeightIntervalType = {
  max: number | null;
  min: number | null;
} | null;

export const WEIGHT_RANGES: Array<WeightRange> = [
  { name: "0 - 100", min: 0, max: 100 },
  { name: "101 - 110", min: 101, max: 110 },
  { name: "111 - 120", min: 111, max: 120 },
  { name: "121 - 130", min: 121, max: 130 },
  { name: "131 - 140", min: 131, max: 140 },
  { name: "141 - 150", min: 141, max: 150 },
  { name: "151 - 160", min: 151, max: 160 },
  { name: "161 - 170", min: 161, max: 170 },
  { name: "171 - 180", min: 171, max: 180 },
  { name: "181 - 190", min: 181, max: 190 },
  { name: "191 - 200", min: 191, max: 200 },
  { name: "201 - 210", min: 201, max: 210 },
  { name: "211 - 220", min: 211, max: 220 },
  { name: "221 - 230", min: 221, max: 230 },
  { name: "231 - 240", min: 231, max: 240 },
  { name: "241 - 250", min: 241, max: 250 },
  { name: "251 - 260", min: 251, max: 260 },
  { name: "261 - 270", min: 261, max: 270 },
  { name: "271 - 280", min: 271, max: 280 },
  { name: "281 - 290", min: 281, max: 290 },
  { name: "291 - 300", min: 291, max: 300 },
  { name: "301 - 500", min: 300, max: 500 },
];

export const getWeightIntervalString = (
  weightInterval: WeightIntervalType | null | undefined,
): string | null => {
  if (!weightInterval) return null;
  const range = WEIGHT_RANGES.find(
    (queriedRange) =>
      queriedRange?.min <= Number(weightInterval) &&
      queriedRange?.max >= Number(weightInterval),
  );
  if (range) {
    return range.name;
  }

  return null;
};

export const getWeightIntervalFromRangeStr = (
  rangeStr: string | null,
): WeightIntervalType | null => {
  if (!rangeStr) return null;

  const weightRange = WEIGHT_RANGES.find((a) => a.name === rangeStr) || {
    min: null,
    max: null,
  };

  const { max, min } = weightRange;

  return {
    min,
    max,
  };
};

export type Interval = "height_interval" | "weight_interval";

export const getInterval = (
  formValue: Patient | null | undefined,
  searchType: SearchType | null | undefined,
  isProviderSearch: boolean,
  interval: Interval,
  translations: Translations,
) => {
  const intervalMinValue = formValue?.profile?.[interval]?.min;
  if (!searchType) return null;

  if (interval === "weight_interval") {
    const intervalMaxValue = formValue?.profile?.[interval]?.max;
    if (formValue?.profile?.[interval]) {
      // covering old weight ranges
      if (!intervalMaxValue) {
        const prefix =
          intervalMinValue === 0
            ? translations.general.lessThan
            : translations.general.moreThan;

        return `${prefix} ${isOverweight(searchType, isProviderSearch)}`;
      }

      return `${intervalMinValue} - ${intervalMaxValue}`;
    }
    return null;
  }

  if (interval === "height_interval") {
    const prefix =
      intervalMinValue === 0
        ? translations.general.smallerThan
        : translations.general.tallerThan;
    if (formValue?.profile?.[interval]) {
      return `${prefix} ${OVERHEIGHT}`;
    }
    return null;
  }
};

export const formatPickUpTime = (value?: AnyObject) => {
  if (!value) return null;
  const formValue = Iterable.isIterable(value) ? value.toJS() : value;

  const time =
    value &&
    setHours(
      setMinutes(
        setSeconds(setMilliseconds(new Date(), 0), 0),
        formValue.minute ?? 0,
      ),
      formValue.hour ?? 0,
    );

  const isValidTime = isValid(time);

  if (!isValidTime) {
    console.error(`pickup time ${JSON.stringify(value)} cannot be parsed`);
    return null;
  }
  return time;
};

export function showSpecializations({
  searchType,
}: {
  searchType?: SearchType;
}) {
  if (!searchType) return false;

  return (
    [
      SEARCH_TYPE_REHABILITATION,
      SEARCH_TYPE_HOSPITAL,
      SEARCH_TYPE_HOME_CARE,
    ] as SearchType[]
  ).includes(searchType);
}

export const BLOCKED_DUPLICATE_SEARCH_TYPES: SearchType[] = [
  SEARCH_TYPE_CARE,
  SEARCH_TYPE_HOSPITAL,
  SEARCH_TYPE_REHABILITATION,
];

export const checkAllowedDuplicateSearchType = ({
  auctions,
  searchType,
}: {
  auctions: Auction[] | undefined;
  searchType: SearchType;
}): boolean => {
  if (auctions === undefined) {
    return true;
  }

  if (!BLOCKED_DUPLICATE_SEARCH_TYPES.includes(searchType)) return true;

  return !auctions.some((auction) => auction.search_type === searchType);
};

export const hasMobileSolution = (solutions: number[]) =>
  solutions?.some((s) => MOBILE_SOLUTIONS.includes(s));
