import {
  SubscriptionHookOptions,
  useApolloClient,
  useSubscription,
} from "@apollo/client";
import { useWebSocketContext } from "apollo/client/WebSocketContext";
import {
  GET_AUCTIONREQUEST,
  ON_CANDIDATES_CALCULATED,
  ON_ENCRYPTION_JOB,
  ON_PENDING_SEALD_ACCESS,
  ON_PENDING_SESSION_KEY,
  ON_REFRESH_SEARCH_MERGE,
  ON_REQUEST_EVENT_RECEIVED,
  ON_REQUEST_STATUS_CHANGED,
} from "core/apollo/graphql";
import { useInterval } from "core/hooks";
import { useApolloEncryptionContext } from "core/model/patients/encryption/utils";
import { useActivityContext } from "core/model/utils/browser/ActivitySingleton";
import { isCI } from "core/model/utils/featureFlags";
import { getReferrer } from "core/model/utils/urls";
import {
  Auction,
  EncryptionJob,
  OnRefreshSearchMerge,
  PendingSealdAccess,
  PendingSessionKey,
} from "core/types";
import gql from "graphql-tag";
import { useEffect, useRef } from "react";
import { getAuctionRequestEventQuery, useLazyGetAuction } from "../queries";

export function useOnPendingSessionKey() {
  const { data, loading } = useSubscription<{
    pendingSessionKey: PendingSessionKey;
  }>(ON_PENDING_SESSION_KEY);
  const pendingSessionKey = data?.pendingSessionKey;
  return [pendingSessionKey, loading] as const;
}

export function useOnPendingSealdAccess() {
  const { data, loading } = useSubscription<{
    pendingSealdAccess: PendingSealdAccess[];
  }>(ON_PENDING_SEALD_ACCESS);
  const pendingSealdAccess = data?.pendingSealdAccess;
  return [pendingSealdAccess, loading] as const;
}

export function useOnRefreshSearchMerge(auctionId: number) {
  const { data, loading } = useSubscription<
    { refreshSearchMerge: OnRefreshSearchMerge },
    { auctionId: number }
  >(ON_REFRESH_SEARCH_MERGE, { variables: { auctionId } });
  const refreshSearchMerge = data?.refreshSearchMerge;
  return [refreshSearchMerge, loading] as const;
}

export const useHandleAutoRefreshComponent = (
  auctionId: number,
  refetch: () => void,
) => {
  const [searchMergeSubscriptionData] = useOnRefreshSearchMerge(auctionId);
  const getAuction = useLazyGetAuction();
  const { isAlive } = useWebSocketContext();
  const { active } = useActivityContext();
  const activeRef = useRef(active);

  const dontPoll = isCI || isAlive || !active;

  // if user goes from inactive to active, then refresh the page to get latest data
  useEffect(() => {
    if (!activeRef.current && active) {
      refetch();
    }
    activeRef.current = active;
  }, [active]);

  // refresh on subscription event
  useEffect(() => {
    if (active && searchMergeSubscriptionData?.full_page_refresh) {
      refetch();
      getAuction(auctionId);
    }
  }, [searchMergeSubscriptionData]);

  // poll every two minutes if no websocket connection and user is active
  useInterval(
    () => {
      refetch();
    },
    dontPoll ? null : 2 * 60 * 1000,
  );
};

export function useOnEncryptionJob() {
  const { data, loading } = useSubscription<{ encryptionJob: EncryptionJob }>(
    ON_ENCRYPTION_JOB,
    {},
  );
  const encryptionJob = data?.encryptionJob;
  return [encryptionJob, loading] as const;
}

export function useOnCandidatesCalculated(
  auctionId: number,
  options: SubscriptionHookOptions<
    { auctionCandidatesComplete: Pick<Auction, "candidates_status" | "id"> },
    { auctionId: number }
  > = {},
): boolean {
  const client = useApolloClient();
  const { loading } = useSubscription<
    { auctionCandidatesComplete: Pick<Auction, "candidates_status" | "id"> },
    { auctionId: number }
  >(ON_CANDIDATES_CALCULATED, {
    variables: { auctionId },
    onData: ({ data: { data } }) => {
      const candidatesStatus =
        data?.auctionCandidatesComplete?.candidates_status;
      if (!candidatesStatus) return;

      const query = gql`
        query Auction {
          auction {
            id
            candidates_status
          }
        }
      `;

      const queryData = {
        auction: {
          id: auctionId,
          candidates_status: candidatesStatus,
          __typename: "Auction",
        },
      };

      client.writeQuery({
        query,
        data: queryData,
      });
    },
    ...options,
  });

  return loading;
}

export function useOnRequestStatusChanged(
  auctionRequestId: number,
  options: SubscriptionHookOptions<
    { requestStatusChanged: { should_refetch: boolean } },
    { auctionRequestId: number }
  > = {},
) {
  const client = useApolloClient();
  const encryptionContext = useApolloEncryptionContext({ decrypt: true });
  useSubscription<
    { requestStatusChanged: { should_refetch: boolean } },
    { auctionRequestId: number }
  >(ON_REQUEST_STATUS_CHANGED, {
    variables: { auctionRequestId },
    onData: ({ data: { data } }) => {
      if (!data?.requestStatusChanged?.should_refetch) {
        return;
      }
      if (auctionRequestId <= 0) {
        console.error(
          `[DEV-14068] [useOnRequestStatusChanged] tried to access bad request id ${auctionRequestId}`,
        );
        return;
      }

      client.query({
        query: GET_AUCTIONREQUEST,
        variables: { id: auctionRequestId, ref: getReferrer() },
        context: { encryptionContext },
        fetchPolicy: "network-only",
      });
    },
    ...options,
  });
}

export function useOnRequestEventReceived(
  auctionRequestId: number,
  options: SubscriptionHookOptions<
    { requestEventReceived: { new_event_received: boolean } },
    { auctionRequestId: number }
  > = {},
): boolean {
  const client = useApolloClient();

  const { loading } = useSubscription<
    { requestEventReceived: { new_event_received: boolean } },
    { auctionRequestId: number }
  >(ON_REQUEST_EVENT_RECEIVED, {
    variables: { auctionRequestId },
    onData: async ({ data: { data } }) => {
      if (!data?.requestEventReceived.new_event_received) return;
      const query = getAuctionRequestEventQuery({
        auctionRequestId,
        withEventLastSeens: true,
      });
      const eventsPayload = await client.query({
        ...query,
        fetchPolicy: "network-only",
      });

      if (!eventsPayload?.data) return;

      client.writeQuery({
        ...query,
        data: eventsPayload.data,
      });
    },
    ...options,
  });

  return loading;
}
