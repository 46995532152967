import { A11yStatusUpdate } from "core/types";
import { debounce } from "lodash";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import A11yStatus from "./A11yStatus";

interface A11yStatusContextType {
  update: A11yStatusUpdate;
  /**
   * Updates the message and role for the A11yStatus component.
   *
   * @param newMessage - The new message to be displayed.
   * @param newRole - The role for the message, either 'status' or 'alert'.
   *
   * @remarks
   * Warning: Because of its intrusive nature, the `role="alert"` must be used sparingly
   * and only in situations where the user's immediate attention is required.
   */
  updateA11yMessage: (
    newMessage: A11yStatusUpdate["message"],
    newRole?: A11yStatusUpdate["role"],
  ) => void;
}

const A11yStatusContext = createContext<A11yStatusContextType | undefined>(
  undefined,
);

const STATUS_DEBOUNCE_TIMEFRAME = 1000;
const MESSAGE_CLEAR_TIMEFRAME = 2000; // Duration to keep the message in ms

export function useA11yMessage() {
  const context = useContext(A11yStatusContext);
  if (!context) {
    throw new Error("useA11yMessage must be used within an A11yStatusProvider");
  }
  return context;
}

export function A11yStatusProvider({ children }: { children: ReactNode }) {
  const [update, setUpdate] = useState<A11yStatusUpdate>({
    message: "",
    role: "status",
  });
  const queue = useRef<string[]>([]);
  const timeoutId = useRef<ReturnType<typeof setTimeout> | null>(null);

  const debouncedStatusUpdate = debounce(() => {
    setUpdate({ message: queue.current.join(", "), role: "status" });
    queue.current = [];
  }, STATUS_DEBOUNCE_TIMEFRAME);

  function updateA11yMessage(
    newMessage: A11yStatusUpdate["message"],
    newRole: A11yStatusUpdate["role"] = "status",
  ) {
    // Alert messages due to their time-sensitive nature, need to happen immediately (or as soon as react can render them).
    // Status messages can  be accumulated and delayed for the debounced timeframe,
    // this allows us to compose complex messages made of different parts of the application
    if (newRole === "alert") {
      setUpdate({ message: newMessage, role: newRole });
    } else {
      queue.current.push(newMessage);
      debouncedStatusUpdate();
    }
  }

  useEffect(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
      timeoutId.current = null;
    }

    if (update.message) {
      timeoutId.current = setTimeout(() => {
        setUpdate({ message: "", role: "status" });
        timeoutId.current = null;
      }, MESSAGE_CLEAR_TIMEFRAME);
    }

    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
        timeoutId.current = null;
      }
    };
  }, [update]);

  return (
    <A11yStatusContext.Provider value={{ update, updateA11yMessage }}>
      <A11yStatus />
      {children}
    </A11yStatusContext.Provider>
  );
}
