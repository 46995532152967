import { FileHISStateful, Filev2 } from "core/types";
import { DescriptionOutlinedIcon, LockIcon } from "ds/icons";
import { GeneralTableTitle } from "ds_legacy/components/Tables/GeneralTable/TableTitle";
import { GREY_600 } from "ds_legacy/materials/colors";
import { HorizontalLayout } from "ds_legacy/materials/layouts";
import { margin, space } from "ds_legacy/materials/metrics";
import { Body, FONT_SIZE_12 } from "ds_legacy/materials/typography";
import { BANNER_WIDTH } from "dsl/organisms/Banners";
import { CSSProperties } from "react";
import { useTranslations } from "translations";

export * from "./ReceivedFiles";
export * from "./SentFiles";

export const maxWidth = `${BANNER_WIDTH / 3}px`;

export function isFileHISStateful(
  file: FileHISStateful | Filev2 | undefined,
): file is FileHISStateful {
  return !!(file as FileHISStateful)?.document_id;
}

export function FilesTableTitle({
  description,
  isMessenger,
  numberOfFiles,
  title,
}: {
  description: string | JSX.Element;
  isMessenger?: boolean;
  numberOfFiles: number;
  title: string;
}) {
  return (
    <GeneralTableTitle
      count={numberOfFiles}
      description={description}
      iconProps={{ Icon: DescriptionOutlinedIcon }}
      title={title}
      variant={isMessenger ? "minimal" : "default"}
    />
  );
}

const containerStyle = {
  gap: space(0.5),
  margin: margin(1, 0),
};

type EncryptedFileMessageProps = {
  /** Style overrides applied to the icon only */
  iconStyle?: CSSProperties;
  /** Size applied to both icon and text */
  size?: CSSProperties["fontSize"];
  /** Style overrides applied to the element (the container of the icon and text) */
  style?: CSSProperties;
  /** Style overrides applied to the text only */
  textStyle?: CSSProperties;
};

export function EncryptedFileMessage({
  iconStyle,
  size,
  style,
  textStyle,
}: EncryptedFileMessageProps) {
  const translations = useTranslations();

  const contentStyle: CSSProperties = {
    fontSize: size ?? FONT_SIZE_12,
    lineHeight: size ?? FONT_SIZE_12,
    color: GREY_600,
    margin: 0,
  };

  return (
    <HorizontalLayout aligned style={{ ...containerStyle, ...style }}>
      <LockIcon
        style={{ ...contentStyle, ...iconStyle }}
        size={contentStyle.fontSize}
      />
      <Body as="p" style={{ ...contentStyle, ...textStyle }}>
        {translations.fileSection.explanationIcon}
      </Body>
    </HorizontalLayout>
  );
}
