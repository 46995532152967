import { MultipleQueriesQuery } from "@algolia/client-search";
import { InputAdornment } from "@mui/material";
import Paper from "@mui/material/Paper";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import {
  StyledEngineProvider,
  Theme,
  ThemeProvider,
} from "@mui/material/styles";
import { CSSProperties } from "@mui/styles";
import algoliasearch from "algoliasearch/lite";
import { useEnvContext } from "context/EnvContext";
import {
  ENV_DEMO,
  ENV_DEVELOPMENT,
  ENV_PREPROD,
  ENV_STAGING,
} from "core/model/config";
import { concatStrings } from "core/model/utils/strings";
import { AlgoliaAnalyticsName, AppType, CSSPosition, Env } from "core/types";
import {
  BORDER_BOTTOM_DARK,
  CUSTOM_BLACK,
  WHITE,
} from "ds_legacy/materials/colors";
import {
  SEARCH_BAR_WIDTH_MEDIUM,
  SEARCH_BAR_WIDTH_SMALL,
  border,
  padding,
} from "ds_legacy/materials/metrics";
import { getTheme } from "ds_legacy/materials/theme";
import {
  Caption,
  FONT_SIZE_14,
  FONT_SIZE_16,
} from "ds_legacy/materials/typography";
import { useOnClickAlgoliaItem } from "dsl/atoms/AlgoliaSelect/search-insights";
import { useBrowserContext } from "dsl/atoms/BrowserProvider";
import { MenuItem } from "dsl/atoms/MenuItem";
import { useGetAnalyticsTags } from "dsl/hooks";
import { ComponentType, ReactNode, useMemo, useState } from "react";
import AutoSuggest, {
  RenderSuggestionParams,
  RenderSuggestionsContainerParams,
} from "react-autosuggest";
import { AutocompleteProvided } from "react-instantsearch-core";
import {
  Configure,
  InstantSearch,
  connectAutoComplete,
} from "react-instantsearch-dom";
import { useCountry } from "reduxentities/selectors/hooks";
import styled from "styled-components";
import { useTranslations } from "translations";
import Translations from "translations/types";
import { useMedia } from "../ResponsiveMedia";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}
export type CustomRenderSuggestionParams = RenderSuggestionParams & {
  isEmpty: boolean;
  isLast: boolean;
};

const TextFieldWithBorder = styled(TextField)<{
  height?: CSSProperties["height"];
}>`
  & .MuiOutlinedInput-root {
    & fieldset {
      border: ${border({ color: BORDER_BOTTOM_DARK })};
    }
    & input::placeholder {
      opacity: 0.9;
    }
    height: ${({ height }) => height};
  }
`;

function RenderInput({
  app,
  autoFocus,
  dark,
  error,
  height,
  helperText,
  label,
  margin = "dense",
  placeholder,
  startAdornment,
  value,
  variant = "standard",
  width,
  ...inputProps
}: {
  app: AppType;
  autoFocus: boolean;
  dark: boolean;
  error: string;
  height: CSSProperties["height"];
  helperText: string;
  label: string;
  margin: TextFieldProps["margin"];
  placeholder: string;
  startAdornment?: JSX.Element;
  value: ToType;
  variant: TextFieldProps["variant"];
  width: string;
}) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={getTheme(app, dark)}>
        <TextFieldWithBorder
          height={height}
          error={error !== null && error !== undefined}
          autoFocus={autoFocus}
          value={value}
          inputProps={inputProps}
          InputProps={{
            startAdornment: startAdornment ? (
              <InputAdornment aria-hidden position="start">
                {startAdornment}
              </InputAdornment>
            ) : null,
          }}
          label={label}
          placeholder={placeholder}
          helperText={helperText}
          sx={{
            width,
            "& .MuiFormLabel-root": {
              fontSize: FONT_SIZE_14,
            },
          }}
          margin={margin}
          variant={variant}
        />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export const defaultRenderSuggestionsContainer = (style?: AnyObject) =>
  function defaultRenderSuggestions({
    children,
    containerProps,
    position,
  }: {
    children: ReactNode;
    containerProps: AnyObject;
    position?: CSSPosition;
  }) {
    return (
      <Paper
        {...containerProps}
        sx={{
          zIndex: 1,
          position: position || "absolute",
          overflow: "auto",
          overflowX: "hidden",
          maxHeight: "450px",
          ...style,
        }}
      >
        {children}
      </Paper>
    );
  };

const defaultRenderSuggestion = (width: ToType) =>
  function renderSuggestion(
    suggestion: AnyObject,
    { isHighlighted }: { isHighlighted: boolean },
  ) {
    return (
      <MenuItem
        component="div"
        selected={isHighlighted}
        data-testid="suggestion-item"
        sx={{
          fontSize: FONT_SIZE_14,
          width,
          padding: padding(1, 2),
          cursor: "pointer",
        }}
      >
        {suggestion.name}
      </MenuItem>
    );
  };

const AutoComplete = connectAutoComplete(
  ({
    alwaysDisplayPlaceholder,
    ariaLabel,
    autoClear,
    clearOnSelect,
    currentRefinement,
    CustomNoResult,
    customNoResultMessage,
    dark = false,
    env,
    error,
    height,
    hide,
    hits,
    indexName,
    indexWithEnv,
    label,
    margin,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    refine,
    renderSuggestion,
    renderSuggestionsContainer,
    startAdornment,
    translations,
    useCustomNoResult,
    value,
    variant,
    width,
  }: AutocompleteProvided & {
    CustomNoResult?: ComponentType<ToType>;
    alwaysDisplayPlaceholder?: boolean;
    ariaLabel?: string;
    autoClear?: boolean;
    clearOnSelect?: boolean;
    currentRefinement: string;
    customNoResultMessage?: string;
    dark: boolean;
    env: Env;
    error?: string;
    height?: CSSProperties["height"];
    hide?: boolean;
    hits: Array<number>;
    indexName: string;
    indexWithEnv?: boolean;
    label?: string;
    margin?: TextFieldProps["margin"];
    onBlur?: () => void;
    onChange: (arg: ToType) => void;
    onFocus?: () => void;
    placeholder: string;
    refine: (v: ToType) => ToType;
    renderSuggestion?: (
      suggestion: any,
      params: CustomRenderSuggestionParams,
    ) => ReactNode;
    renderSuggestionsContainer?: (
      params: RenderSuggestionsContainerParams,
    ) => ReactNode;
    small: boolean;
    startAdornment?: JSX.Element;
    translations: Translations;
    useCustomNoResult?: boolean;
    value: ToType;
    variant?: TextFieldProps["variant"];
    width: number | string;
  }) => {
    const { onClickAlgoliaItem } = useOnClickAlgoliaItem();
    const [inputValue, setInputValue] = useState<string | null>(null);
    const valueName = value?.name || "";
    const displayInputValue = inputValue != null ? inputValue : valueName;
    const { isChrome } = useBrowserContext();
    const { isMobile } = useMedia();

    const hasNoResults = displayInputValue.length && !hits.length;

    const helperText =
      error ||
      (!hits.length &&
        currentRefinement !== "" &&
        currentRefinement !== displayInputValue &&
        CustomNoResult && (
          <CustomNoResult currentRefinement={currentRefinement} />
        )) ||
      (useCustomNoResult &&
        !hits.length &&
        currentRefinement !== "" &&
        currentRefinement !== displayInputValue &&
        customNoResultMessage) ||
      (hasNoResults && translations.general.noResult) ||
      "";

    const inputProps = {
      "aria-label": ariaLabel,
      autoComplete: isChrome ? `new-${indexName || "input"}` : "off",
      dark,
      error,
      height,
      helperText,
      id: indexName,
      label,
      margin,
      onBlur,
      onFocus,
      onChange: (e: ToType, { newValue }: { newValue: ToType }) => {
        if (newValue === "") {
          onChange(null);
          refine("");
          setInputValue(newValue);
        } else if (typeof newValue === "string") {
          setInputValue(valueName);
        } else {
          setInputValue(newValue.name);
        }
      },
      placeholder,
      startAdornment,
      value: currentRefinement || displayInputValue,
      variant,
      width,
    };

    return (
      <div>
        {alwaysDisplayPlaceholder && (
          <Caption margin="0px">
            {inputValue && placeholder}
            &nbsp;
          </Caption>
        )}
        <AutoSuggest<any, any>
          suggestions={
            useCustomNoResult &&
            !customNoResultMessage &&
            !CustomNoResult &&
            hits &&
            hits.length === 0
              ? [{ isEmpty: true }]
              : hits
          }
          onSuggestionsFetchRequested={({ value }) => {
            refine(value);
          }}
          onSuggestionsClearRequested={() => {
            if (autoClear) {
              refine("");
            }
          }}
          onSuggestionSelected={(e, { suggestion }) => {
            onChange(suggestion);

            onClickAlgoliaItem({
              index: getIndex(indexName, env, indexWithEnv),
              objectIDs: [suggestion.objectID],
              positions: [suggestion.__position],
              queryID: suggestion.__queryID,
            });

            if (clearOnSelect) {
              refine("");
              setInputValue("");
            } else {
              const suggestionValue = suggestion?.name || "";
              refine(suggestionValue);
            }
          }}
          getSuggestionValue={(v) => v}
          renderInputComponent={RenderInput as any}
          renderSuggestionsContainer={
            renderSuggestionsContainer || defaultRenderSuggestionsContainer()
          }
          renderSuggestion={(suggestion, params) => {
            const isEmpty = hits.length === 0;
            const isLast =
              hits.length > 0 &&
              suggestion.objectID === hits[hits.length - 1].objectID;
            const customParams = { ...params, isEmpty, isLast };

            if (renderSuggestion) {
              return renderSuggestion?.(suggestion, customParams);
            }
            return defaultRenderSuggestion(width)(suggestion, customParams);
          }}
          focusInputOnSuggestionClick={false}
          inputProps={inputProps}
          theme={{
            container: {
              display: hide ? "none" : undefined,
            },
            input: {
              fontSize: isMobile ? FONT_SIZE_16 : FONT_SIZE_14,
              color: dark ? WHITE : CUSTOM_BLACK,
            },
            suggestionsList: {
              listStyle: "none",
              padding: "0",
              margin: "0",
            },
            suggestion: {
              padding: "0",
              margin: "0",
            },
          }}
        />
      </div>
    );
  },
);

export function getIndex(
  name: string,
  env: string,
  withEnv: boolean | null | undefined,
): string {
  if (!withEnv) return name;

  const envMap: { [key: string]: string } = {
    [ENV_DEVELOPMENT]: ENV_STAGING,
    [ENV_DEMO]: ENV_PREPROD,
  };

  return concatStrings(name, (envMap[env] || env).toUpperCase(), "_");
}

export const useGetAlgoliaApiKey = () => {
  const { app, config } = useEnvContext();
  const country = useCountry();

  const appId = config?.algolia.appId ?? "";
  const apiKey = config?.algolia.apiKey ?? "";

  if (!apiKey) {
    console.error(`missing algolia key for app: ${app} country: ${country}`);
  }
  if (!appId) {
    console.error(`missing algolia app id for country: ${country}`);
  }

  return { appId, apiKey };
};

export default function AlgoliaSearchBar({
  algoliaAnalyticsName,
  alwaysDisplayPlaceholder,
  ariaLabel,
  aroundLatLng,
  aroundRadius,
  attributesToRetrieve,
  className,
  clearOnSelect,
  CustomNoResult,
  customNoResultMessage,
  dark = false,
  error,
  filters,
  fullsize = false,
  height,
  hide,
  hitsPerPage,
  indexName,
  indexWithEnv,
  label,
  margin,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  renderSuggestion,
  renderSuggestionsContainer,
  restrictSearchableAttributes,
  small = false,
  startAdornment,
  useCustomNoResult,
  value,
  variant,
}: {
  CustomNoResult?: ComponentType<ToType>;
  algoliaAnalyticsName: AlgoliaAnalyticsName;
  alwaysDisplayPlaceholder?: boolean;
  ariaLabel?: string;
  aroundLatLng?: string;
  aroundRadius?: number;
  attributesToRetrieve?: string[];
  autoClear?: boolean;
  className?: string;
  clearOnSelect?: boolean;
  customNoResultMessage?: string;
  dark?: boolean;
  error?: string;
  filters?: string;
  fullsize?: boolean;
  height?: CSSProperties["height"];
  hide?: boolean;
  hitsPerPage?: number;
  indexName: string;
  indexWithEnv?: boolean;
  label?: string;
  margin?: TextFieldProps["margin"];
  onBlur?: () => void;
  onChange: (arg: ToType) => void;
  onFocus?: () => void;
  placeholder: string;
  renderSuggestion?: (
    suggestion: any,
    params: CustomRenderSuggestionParams,
  ) => ReactNode;
  renderSuggestionsContainer?: (
    params: RenderSuggestionsContainerParams,
  ) => ReactNode;
  restrictSearchableAttributes?: string[];
  small?: boolean;
  startAdornment?: JSX.Element;
  useCustomNoResult?: boolean;
  value?: ToType;
  variant?: TextFieldProps["variant"];
}) {
  const { env } = useEnvContext();
  const { apiKey, appId } = useGetAlgoliaApiKey();
  const analyticsTags = useGetAnalyticsTags({ algoliaAnalyticsName });
  const translations = useTranslations();

  const searchClient = useMemo(() => {
    const algoliaClient = algoliasearch(appId, apiKey);
    return {
      ...algoliaClient,
      // disables empty searches = empty queries / initial focus won't trigger results by default
      search(requests: MultipleQueriesQuery[]) {
        if (requests.every(({ params }) => !params?.query)) {
          return Promise.resolve({
            results: requests.map(() => ({
              hits: [],
              nbHits: 0,
              nbPages: 0,
              page: 0,
              processingTimeMS: 0,
              hitsPerPage: 0,
              exhaustiveNbHits: false,
              query: "",
              params: "",
            })),
          });
        }

        return algoliaClient.search(
          requests as Parameters<typeof algoliaClient.search>[0],
        );
      },
    };
  }, [appId, apiKey]);

  const width = fullsize
    ? "100%"
    : small
    ? SEARCH_BAR_WIDTH_SMALL
    : SEARCH_BAR_WIDTH_MEDIUM;

  return (
    <div className={className} style={{ width, height }}>
      <InstantSearch
        indexName={getIndex(indexName, env, indexWithEnv)}
        searchClient={searchClient}
      >
        <Configure
          analyticsTags={analyticsTags}
          aroundLatLng={aroundLatLng}
          aroundRadius={aroundRadius}
          attributesToRetrieve={attributesToRetrieve}
          clickAnalytics
          filters={filters}
          hitsPerPage={hitsPerPage || 5}
          restrictSearchableAttributes={restrictSearchableAttributes}
        />
        <AutoComplete
          alwaysDisplayPlaceholder={alwaysDisplayPlaceholder}
          ariaLabel={ariaLabel}
          clearOnSelect={clearOnSelect}
          CustomNoResult={CustomNoResult}
          customNoResultMessage={customNoResultMessage}
          dark={dark}
          env={env}
          error={error}
          height={height}
          hide={hide}
          indexName={indexName}
          indexWithEnv={indexWithEnv}
          label={label}
          margin={margin}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
          placeholder={placeholder}
          renderSuggestion={renderSuggestion}
          renderSuggestionsContainer={renderSuggestionsContainer}
          small={small}
          startAdornment={startAdornment}
          translations={translations}
          useCustomNoResult={useCustomNoResult}
          value={value}
          variant={variant}
          width={width}
        />
      </InstantSearch>
    </div>
  );
}
