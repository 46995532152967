import {
  ONBOARDING_PAGE_ACCOUNT,
  ONBOARDING_PAGE_ADDITIONAL_INFORMATION,
  ONBOARDING_PAGE_COMPANY,
  ONBOARDING_PAGE_CONGRATULATIONS,
  ONBOARDING_PAGE_PASSWORD,
  ONBOARDING_PAGE_PASSWORD_RESET,
  ONBOARDING_PAGE_SERVICES,
  ONBOARDING_PAGE_WELCOME,
  ONBOARDING_PAGE_YOUR_COMPANY,
  ONBOARDING_PAGE_YOUR_TEAM,
} from "core/consts";
import Translations from "translations/types";

export type StepperStep =
  | typeof ONBOARDING_PAGE_ACCOUNT
  | typeof ONBOARDING_PAGE_ADDITIONAL_INFORMATION
  | typeof ONBOARDING_PAGE_COMPANY
  | typeof ONBOARDING_PAGE_PASSWORD
  | typeof ONBOARDING_PAGE_SERVICES
  | typeof ONBOARDING_PAGE_PASSWORD_RESET;

export type StepperStepPersonalOnboarding =
  | typeof ONBOARDING_PAGE_ACCOUNT
  | typeof ONBOARDING_PAGE_PASSWORD;

export type StepperStepCompanyOnboarding =
  | typeof ONBOARDING_PAGE_YOUR_COMPANY
  | typeof ONBOARDING_PAGE_YOUR_TEAM;

export type Step =
  | StepperStep
  | typeof ONBOARDING_PAGE_CONGRATULATIONS
  | typeof ONBOARDING_PAGE_WELCOME
  | typeof ONBOARDING_PAGE_PASSWORD_RESET;

export const STEPS: Array<Step> = [
  ONBOARDING_PAGE_WELCOME,
  ONBOARDING_PAGE_COMPANY,
  ONBOARDING_PAGE_SERVICES,
  ONBOARDING_PAGE_ADDITIONAL_INFORMATION,
  ONBOARDING_PAGE_ACCOUNT,
  ONBOARDING_PAGE_PASSWORD,
  ONBOARDING_PAGE_CONGRATULATIONS,
  ONBOARDING_PAGE_PASSWORD_RESET,
];

export const STEPPER_STEPS: Array<StepperStep> = [
  ONBOARDING_PAGE_COMPANY,
  ONBOARDING_PAGE_SERVICES,
  ONBOARDING_PAGE_ADDITIONAL_INFORMATION,
  ONBOARDING_PAGE_ACCOUNT,
  ONBOARDING_PAGE_PASSWORD,
];

const STEPPER_STEPS_PERSONAL: Array<StepperStepPersonalOnboarding> = [
  ONBOARDING_PAGE_ACCOUNT,
  ONBOARDING_PAGE_PASSWORD,
];

const STEPPER_STEPS_COMPANY: Array<StepperStepCompanyOnboarding> = [
  ONBOARDING_PAGE_YOUR_COMPANY,
  ONBOARDING_PAGE_YOUR_TEAM,
];

const ONBOARDING_TYPE_PERSONAL = "personalOnboarding";
const ONBOARDING_TYPE_COMPANY = "companyOnboarding";

export function getStepperSteps(
  translations: Translations,
  onboardingType?: string,
): Array<{ key: string; value: string }> {
  switch (onboardingType) {
    case ONBOARDING_TYPE_PERSONAL:
      return STEPPER_STEPS_PERSONAL.map((step) => ({
        key: step,
        value: translations.newOnboardingTwo.stepper[step],
      }));
    case ONBOARDING_TYPE_COMPANY:
      return STEPPER_STEPS_COMPANY.map((step) => ({
        key: step,
        value: translations.newOnboardingTwo.stepper[step],
      }));
    default:
      return STEPPER_STEPS.map((step) => {
        let newStep = step;
        // Display the password reset component on the Account page step where they initially triggered the password reset,
        // without creating a dedicated step for it
        if (newStep === ONBOARDING_PAGE_PASSWORD_RESET) {
          newStep = ONBOARDING_PAGE_ACCOUNT;
        }

        return {
          key: newStep,
          value: translations.newOnboardingTwo.stepper[newStep],
        };
      });
  }
}
