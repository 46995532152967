import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import makeStyles from "@mui/styles/makeStyles";
import { useEnvContext } from "context/EnvContext";
import { APP_ACP } from "core/consts";
import { isChecked } from "ds_legacy/components/CheckboxInputField";
import { TEXT_DARK_PRIMARY } from "ds_legacy/materials/colors";
import { HorizontalLayout } from "ds_legacy/materials/layouts";
import { dp, important, space } from "ds_legacy/materials/metrics";
import { FONT_SIZE_14, FONT_SIZE_15 } from "ds_legacy/materials/typography";
import { CSSProperties } from "react";
import { FormElement } from "react-forms-state";

export type ToggleInputFieldProps = {
  disableChange?: boolean;
  elementName: string;
  important?: boolean;
  label?: string;
  noIndent?: boolean;
  onBeforeChange?: (value: number) => any;
  onChange: (arg: boolean) => void;
  value?: boolean;
  wrapperStyle?: CSSProperties;
};

// fix focus outline
const commonButtonBase = {
  "& .MuiButtonBase-root": {
    outlineOffset: dp(-6),
  },
};

const useStyles = makeStyles({
  root: Object.assign(
    {
      marginLeft: space(2),
    },
    commonButtonBase,
  ),
  noIndentRoot: Object.assign(
    {
      marginLeft: 0,
    },
    commonButtonBase,
  ),
  importantLabel: Object.assign(
    {
      fontWeight: "bold",
      fontSize: important(FONT_SIZE_15),
      color: TEXT_DARK_PRIMARY,
    },
    commonButtonBase,
  ),
  label: Object.assign(
    {
      fontSize: important(FONT_SIZE_14),
      color: TEXT_DARK_PRIMARY,
    },
    commonButtonBase,
  ),
});

export function ToggleInputFieldPresenter({
  disableChange,
  elementName,
  important,
  label,
  noIndent,
  onBeforeChange,
  onChange,
  value,
  wrapperStyle,
}: ToggleInputFieldProps) {
  const { app } = useEnvContext();
  const classes = useStyles();

  const onChangeHandler = (e: ToType, checked: ToType) => {
    if (disableChange) return;
    if (onChange && onBeforeChange) {
      onChange(onBeforeChange(checked));
    } else if (onChange) {
      onChange(checked);
    }
  };

  return (
    <HorizontalLayout padding="0px 0px 0px 3px" style={{ ...wrapperStyle }}>
      <FormControlLabel
        disabled={disableChange}
        name={elementName}
        classes={{
          root: noIndent ? classes.noIndentRoot : classes.root,
          label: important ? classes.importantLabel : classes.label,
        }}
        control={
          <Switch
            disabled={disableChange}
            color={app === APP_ACP ? "default" : "primary"}
            checked={isChecked(value)}
            onChange={onChangeHandler}
            aria-label="checkedB"
          />
        }
        label={label}
      />
    </HorizontalLayout>
  );
}

export default FormElement()(ToggleInputFieldPresenter);
