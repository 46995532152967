import { DOCUMENT_TERMS_AND_CONDITIONS } from "core/consts";
import CheckboxInputField from "ds_legacy/components/CheckboxInputField";
import InfoCaption from "ds_legacy/components/InfoCaption";
import { dp, margin } from "ds_legacy/materials/metrics";
import { Body, Link } from "ds_legacy/materials/typography";
import { FormWatcher, isValid } from "react-forms-state";
import styled from "styled-components";
import { useTranslations } from "translations";
import { TranslationComposition } from "translations/helpers";
import { useLegalDocuments } from "./LegalDocuments";

const TermsAndConditionsText = styled.div`
  flex: 1 1 auto;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  align-self: center;
`;

const TermsAndConditionsBox = styled.div`
  margin: ${margin(2, 3)};
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  align-items: center;
`;

export const TermsAndConditions = () => {
  const { getDocument } = useLegalDocuments();
  const termsAndConditions = getDocument(DOCUMENT_TERMS_AND_CONDITIONS);
  const translations = useTranslations();

  if (!termsAndConditions?.needs_to_sign) return null;

  return (
    <>
      <TermsAndConditionsBox>
        <CheckboxInputField
          width={dp(20)}
          elementName="terms_and_conditions"
          label=""
        />
        <FormWatcher watchPath="terms_and_conditions">
          {({ watchedValidation }) => (
            <TermsAndConditionsText>
              <Body error={!isValid(watchedValidation)}>
                <TranslationComposition
                  translations={translations.termsAndConditions.accept}
                  withOptions
                >
                  {([before, link, after]) => (
                    <>
                      {before}
                      <Link
                        href={termsAndConditions.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          margin: "0px",
                        }}
                      >
                        {link}
                      </Link>
                      {after}
                    </>
                  )}
                </TranslationComposition>
              </Body>
            </TermsAndConditionsText>
          )}
        </FormWatcher>
      </TermsAndConditionsBox>
      {termsAndConditions.needs_to_sign && (
        <InfoCaption
          text={translations.legal.differencesInTCVersions}
          margin={margin(0, 3)}
        />
      )}
    </>
  );
};
