import { useEnvContext } from "context/EnvContext";
import {
  APP_CAREPROVIDER,
  APP_CLINIC,
  CARESEEKER_TYPE_INSURANCE,
} from "core/consts";
import { Careprovider, Careseeker } from "core/types";
import { GREY_600 } from "ds_legacy/materials/colors";
import { HorizontalLayout } from "ds_legacy/materials/layouts";
import { margin } from "ds_legacy/materials/metrics";
import { Body } from "ds_legacy/materials/typography";
import { useSenderAccountContext } from "dsl/atoms/Contexts";
import React from "react";
import { useTranslations } from "translations";
import { TranslationComposition } from "translations/helpers";

type Recipients = string | [string, string];

type RecipientsHookProps = {
  careprovider?: Careprovider;
  careproviderName?: string;
  careseeker?: Careseeker;
  careseekerName?: string;
  insurance?: Careseeker;
  insuranceName?: string;
};

type MessageRecipientHintProps = RecipientsHookProps & {
  directPatient?: string | null;
  isFile?: boolean;
  isMessenger?: boolean;
};

function HintContainer({
  children,
  isMessenger,
}: {
  children: React.ReactNode;
  isMessenger?: boolean;
}) {
  return (
    <HorizontalLayout justify="top" margin={margin(0, isMessenger ? 0 : 1)}>
      <Body as="p" color={GREY_600}>
        {children}
      </Body>
    </HorizontalLayout>
  );
}

export function MessageRecipientHintView({
  isFile,
  isMessenger,
  recipients,
}: {
  isFile: boolean;
  isMessenger: boolean;
  recipients: Recipients;
}) {
  const translations = useTranslations();

  if (Array.isArray(recipients)) {
    const [facility, secondFacility] = recipients;

    return (
      <HintContainer isMessenger={isMessenger}>
        <TranslationComposition
          translations={
            isFile
              ? translations.messenger.fileReceiversHint({
                  facility,
                  secondFacility,
                })
              : translations.messenger.messageReceiversHint({
                  facility,
                  secondFacility,
                })
          }
          withOptions
        >
          {([before, f, and, f2, after]) => (
            <>
              {before}
              <b>{f}</b>
              {and}
              <b>{f2}</b>
              {after}
            </>
          )}
        </TranslationComposition>
      </HintContainer>
    );
  } else {
    const facility = recipients;

    return (
      <HintContainer isMessenger={isMessenger}>
        <TranslationComposition
          translations={
            isFile
              ? translations.messenger.fileReceiverHint({ facility })
              : translations.messenger.messageReceiverHint({ facility })
          }
          withOptions
        >
          {([before, f, after]) => (
            <>
              {before}
              <b>{f}</b>
              {after}
            </>
          )}
        </TranslationComposition>
      </HintContainer>
    );
  }
}

function useRecipients({
  careprovider,
  careproviderName: careproviderNameArg,
  careseeker,
  careseekerName: careseekerNameArg,
  insurance,
  insuranceName: insuranceNameArg,
}: RecipientsHookProps): Recipients {
  const { app } = useEnvContext();
  const { careseeker: loggedCareseeker } = useSenderAccountContext();

  const insuranceName = insurance?.name ?? insuranceNameArg ?? "";
  const senderName = careseeker?.name ?? careseekerNameArg ?? "";
  const receiverName = careprovider?.name ?? careproviderNameArg ?? "";

  switch (app) {
    case APP_CAREPROVIDER:
      return insuranceName ? [senderName, insuranceName] : senderName;
    case APP_CLINIC:
    default: {
      if (insuranceName) {
        if (loggedCareseeker?.type === CARESEEKER_TYPE_INSURANCE)
          return receiverName ? [senderName, receiverName] : senderName;
        return receiverName ? [receiverName, insuranceName] : insuranceName;
      }

      return receiverName;
    }
  }
}

export default function MessageRecipientHint({
  directPatient,
  isFile = false,
  isMessenger = false,
  ...recipientsProps
}: MessageRecipientHintProps) {
  const recipients = useRecipients(recipientsProps);

  if (Array.isArray(recipients) ? recipients.some((s) => !s) : !recipients)
    return null;

  return (
    <MessageRecipientHintView
      recipients={directPatient ?? recipients}
      isFile={isFile}
      isMessenger={isMessenger}
    />
  );
}
