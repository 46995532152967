import {
  COLUMN_TYPE_FILES_CATEGORY,
  COLUMN_TYPE_FILES_FILE_NAME,
  COLUMN_TYPE_FILES_SHARED_WITH,
  QUERY_PROGRESS_PENDING,
  TRACK_EVENTS,
} from "core/consts";
import { FileTableActions, Filev2, QueryProgress } from "core/types";
import GeneralTable from "ds_legacy/components/Tables/GeneralTable";
import {
  COLUMN_TYPE_DEFAULT_DATE,
  COLUMN_TYPE_DEFAULT_TOOLTIP,
} from "ds_legacy/components/Tables/GeneralTable/CustomCells";
import { EmptyV2 } from "ds_legacy/components/Tables/GeneralTable/EmptyComponent";
import { SetAction } from "ds_legacy/components/Tables/GeneralTable/MenuActions";
import {
  getAllowList,
  getFileListActionOptions,
} from "ds_legacy/components/Tables/GeneralTable/MenuActions/Files";
import { VerticalLayout } from "ds_legacy/materials/layouts";
import { FileFeatureData } from "dsl/ecosystems/PatientFiles/useFilesFeature";
import { getFileName } from "dsl/ecosystems/PatientFiles/utils";
import { useTracking } from "react-tracking";
import { useTranslations } from "translations";
import { EncryptedFileMessage, FilesTableTitle } from ".";

export type ReceivedFilesProps = {
  canAccessPatientData: boolean;
  canReceiveFiles?: boolean;
  careseekerId: number | null;
  files?: Filev2[] | null;
  isMessenger?: boolean;
  queryProgress: QueryProgress;
  setAction: SetAction<FileFeatureData>;
};

export function ReceivedFiles({
  canAccessPatientData,
  canReceiveFiles = true,
  careseekerId,
  files,
  isMessenger,
  queryProgress,
  setAction,
}: ReceivedFilesProps) {
  const translations = useTranslations();
  const { trackEvent } = useTracking();

  const description = isMessenger ? (
    <EncryptedFileMessage />
  ) : (
    translations.fileSection.fileUploadComponent.descriptionFilesReceived
  );

  return (
    <VerticalLayout>
      <FilesTableTitle
        description={description}
        isMessenger={isMessenger}
        numberOfFiles={files?.length ?? 0}
        title={translations.fileSection.fileListComponent.titleFilesReceived}
      />
      <GeneralTable<Filev2, FileTableActions>
        id="received-files"
        isLoading={queryProgress === QUERY_PROGRESS_PENDING}
        data={files || []}
        columns={[
          {
            getProps: "category",
            title: translations.fileSection.fileListComponent.category,
            type: COLUMN_TYPE_FILES_CATEGORY,
            width: "5%",
          },
          {
            getProps: (file) => getFileName({ file, canAccessPatientData }),
            title: translations.fileSection.fileListComponent.fileName,
            type: COLUMN_TYPE_FILES_FILE_NAME,
            width: isMessenger ? "45%" : "30%",
          },
          {
            getProps: ({ careprovider, careseeker }) => ({
              value: careprovider?.name ?? careseeker?.name,
            }),
            isHidden: isMessenger,
            title: translations.fileSection.fileListComponent.uploadedBy,
            type: COLUMN_TYPE_DEFAULT_TOOLTIP,
          },
          {
            getProps: ({ created_at }) => ({
              value: created_at,
            }),
            title: translations.fileSection.fileListComponent.shareDate,
            type: COLUMN_TYPE_DEFAULT_DATE,
          },
          {
            getProps: ({ share_mode, shared_with }) => ({
              share_mode,
              shared_with,
              careseekerId,
            }),
            isHidden: isMessenger,
            title: translations.fileSection.fileListComponent.sharedWith,
            type: COLUMN_TYPE_FILES_SHARED_WITH,
          },
        ]}
        actions={{
          allowList: () => getAllowList({ fileTable: "received" }),
          getOptions: ({ data: file, translations }) =>
            getFileListActionOptions({
              canAccessPatientData,
              file,
              fileTable: "received",
              setAction,
              trackEvent,
              translations,
            }),
          getTestId: (file) => `action-received-file-${file.id}`,
          onActionMenuClick: () => {
            trackEvent({
              name: TRACK_EVENTS.FILE_MENU_CLICKED,
              section: "received",
              file_implementation: "HIS",
            });
          },
        }}
        emptyComponent={
          <EmptyV2
            isMessenger={isMessenger}
            title={
              canReceiveFiles
                ? translations.fileSection.fileListComponent
                    .placeholderNothingReceived
                : translations.messenger.sender.fileRestrictionHint
            }
          />
        }
      />
    </VerticalLayout>
  );
}
export default ReceivedFiles;
