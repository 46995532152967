import {
  AccessTime as AccessTimeIconMui,
  AccessibilityNew as AccessibilityNewMui,
  AccessibleForward as AccessibleForwardIconMui,
  AccountBox as AccountIconMui,
  Add as AddIconMui,
  Room as AddressIconMui,
  VpnKey as AdminIconMui,
  DateRange as AgeIconMui,
  Alarm as AlarmIconMui,
  AlarmOn as AlarmOnIconMui,
  Archive as ArchiveIconMui,
  ArchiveOutlined as ArchiveOutlinedIconMui,
  ArrowBack as ArrowBackIconMui,
  ArrowBackIos as ArrowBackIosIconMui,
  ArrowDropDown as ArrowDownMui,
  ArrowDownward as ArrowDownwardIconMui,
  ArrowForwardIosSharp as ArrowForwardIosSharpIconMui,
  KeyboardArrowLeft as ArrowLeftIconMui,
  KeyboardArrowRight as ArrowRightIconMui,
  ArrowDropUp as ArrowUpMui,
  Assignment as AssignmentIconMui,
  AttachFile as AttachFileMui,
  Autorenew as AutorenewIconMui,
  BadgeOutlined as BadgeOutlinedIconMui,
  Block as BlockIconMui,
  BorderOuter as BorderOuterMui,
  Business as BusinessIconMui,
  Cached as CachedIconMui,
  Cancel as CancelIconMui,
  Store as CareproviderIconMui,
  Chat as ChatIconMui,
  CheckCircle as CheckCircleIconMui,
  CheckCircleOutline as CheckCircleOutlineIconMui,
  CheckCircleRounded as CheckCircleRoundedIconMui,
  Check as CheckIconMui,
  ChevronLeft as ChevronLeftMui,
  ChevronRight as ChevronRightIconMui,
  ChevronRight as ChevronRightMui,
  Circle as CircleIconMui,
  Clear as ClearIconMui,
  Business as ClinicIconMui,
  Close as CloseIconMui,
  CloseOutlined as CloseOutlinedIconMui,
  CloseRounded as CloseRoundedIconMui,
  CloudDone as CloudDoneIconMui,
  CloudDownload as CloudDownloadIconMui,
  CloudOff as CloudOffIconMui,
  Person as ContactIconMui,
  ContactMail as ContactMailMui,
  ContentCopy as ContentCopyIconMui,
  ContentPasteOff as ContentPasteOffIconMui,
  ControlPoint as ControlPointIconMui,
  FileCopy as CopyMui,
  Create as CreateIconMui,
  DateRange as DateRangeIconMui,
  Delete as DeleteIconMui,
  DeleteOutlined as DeleteOutlinedIconMui,
  Description as DescriptionIconMui,
  DescriptionOutlined as DescriptionOutlinedIconMui,
  DirectionsOutlined,
  DoNotDisturbOn as DoNotDisturbOnMui,
  Done as DoneIconMui,
  DoneOutlined as DoneOutlinedIconMui,
  Download as DownloadIconMui,
  Drafts as DraftsIconMui,
  DragIndicator as DragIndicatorMui,
  Dvr as DvrIconMui,
  Edit as EditIconMui,
  Email as EmailMui,
  Error as ErrorIconMui,
  ErrorOutline as ErrorOutlineIconMui,
  Event as EventMui,
  ExpandLess as ExpandLessIconMui,
  ExpandMore as ExpandMoreIconMui,
  Explore as ExploreIconMui,
  Extension as ExtensionIconMui,
  Face as FaceIconMui,
  FileCopy as FileCopyIconMui,
  FileCopyOutlined as FileCopyOutlinedIconMui,
  FileUploadOutlined as FileUploadOutlinedMui,
  FilterList as FilterListIconMui,
  Close as FiltersCloseIconMui,
  FindInPage as FindInPageIconMui,
  FormatListBulleted as FormatListBulletedIconMui,
  GetApp as GetAppIconMui,
  Group as GroupIconMui,
  Help as HelpIconMui,
  HelpOutline as HelpOutlineMui,
  HighlightOff as HighlightOffIconMui,
  HistoryEdu as HistoryEduIconMui,
  Home as HomeIconMui,
  Hotel as HotelIconMui,
  HourglassFull as HourGlassFullIconMui,
  HourglassEmpty as HourglassEmptyIconMui,
  Info as InfoIconMui,
  InfoOutlined as InfoOutlinedMui,
  InsertEmoticon as InsertEmoticonIconMui,
  InsertInvitation as InsertInvitationIconMui,
  IosShare as IosShareIconMui,
  KeyboardArrowDown as KeyboardArrowDownMui,
  KeyboardArrowRight as KeyboardArrowRightIconMui,
  KeyboardBackspace as KeyboardBackspaceIconMui,
  Language as LanguageMui,
  Launch as LaunchIconMui,
  Link as LinkIconMui,
  LocalHospital as LocalHospitalMui,
  LocalMall as LocalMallIconMui,
  LocalPrintshop as LocalPrintIconMui,
  LocalPrintshopOutlined as LocalPrintshopOutlinedIconMui,
  LocationCity as LocationCityMui,
  LocationOn as LocationOnIconMui,
  LocationSearching as LocationSearchingMui,
  Lock as LockIconMui,
  Lock as LockMui,
  Logout as LogoutIconMui,
  Mail as MailMui,
  MailOutline as MailOutlineIconMui,
  Map as MapIconMui,
  MenuBook as MenuBookIconMui,
  Menu as MenuIconMui,
  Message as MessageIconMui,
  Smartphone as MobilePhoneIconMui,
  ModeEditOutlineOutlined as ModeEditOutlineOutlinedIconMui,
  MoreVert as MoreVertIconMui,
  NavigateBefore as NavigateBeforeIconMui,
  NavigateNext as NavigateNextIconMui,
  NoEncryptionOutlined as NoEncryptionOutlinedIconMui,
  NotInterested as NotInterestedIconMui,
  Notifications as NotificationsIconMui,
  OpenInNew as OpenInNewIconMui,
  OutlinedFlag as OutlinedFlagMui,
  Pause as PauseIconMui,
  Pending as PendingIconMui,
  PermContactCalendar as PermContactCalendarIconMui,
  PersonAddDisabled as PersonAddDisabledIconMui,
  PersonAdd as PersonAddMui,
  Person as PersonIconMui,
  PersonOutline as PersonOutlineIconMui,
  PhoneAndroid as PhoneAndroidIconMui,
  Phone as PhoneIconMui,
  Phone as PhoneMui,
  Photo as PhotoIconMui,
  Place as PlaceIconMui,
  PlayArrow as PlayArrowIconMui,
  PlaylistAddCheck as PlaylistAddCheckIconMui,
  PlaylistAdd as PlaylistAddIconMui,
  Print as PrintMui,
  PriorityHigh as PriorityHighIconMui,
  QueryBuilder as QueryBuilderIconMui,
  QuestionMark as QuestionMarkIconMui,
  Refresh as RefreshIconMui,
  RemoveRedEye as RemoveRedEyeMui,
  Room as RoomIconMui,
  Save as SaveIconMui,
  Search as SearchIconMui,
  Send as SendIconMui,
  SentimentSatisfied as SentimentSatisfiedMui,
  Settings as SettingsIconMui,
  Sms as SmsIconMui,
  StarBorder as StarBorderIconMui,
  Star as StarIconMui,
  StarRate as StarRateMui,
  Stars as StarsIconMui,
  Stop as StopIconMui,
  SupervisorAccount as SupervisorAccountIconMui,
  TaskAlt as TaskAltIconMui,
  ThumbDownAlt as ThumbDownAltMui,
  ThumbUpAlt as ThumbUpAltMui,
  Today as TodayIconMui,
  Translate as TranslateIconMui,
  Undo as UndoIconMui,
  UploadFile as UploadFileMui,
  CloudUpload as UploadIconMui,
  VerifiedUser as VerifiedIconMui,
  Visibility as VisibilityIconMui,
  VisibilityOff as VisibilityOffMui,
  VolunteerActivism as VolunteerActivismIconMUI,
  WarningAmberSharp as WarningAmberSharpMui,
  Warning as WarningIconMui,
  SettingsInputSvideo as WeightIconMui,
} from "@mui/icons-material";
import { isNewTheme } from "core/model/utils/featureFlags";
import { ValueOf } from "core/types";
import {
  AlarmCheck,
  AlarmClock,
  AlertOctagon,
  AlertTriangle,
  Archive,
  ArrowDown as ArrowDownLuicide,
  ArrowLeft as ArrowLeftLuicide,
  ArrowRight as ArrowRightLuicide,
  Ban,
  Bed,
  Bell,
  BlocksIcon,
  BookOpenText,
  BookOpenTextIcon,
  Building,
  Building2Icon,
  CalendarDays,
  Check,
  CheckCircle as CheckCircleLucide,
  ChevronDown,
  ChevronLeft as ChevronLeftLuicide,
  ChevronRight as ChevronRightLuicide,
  ChevronUp,
  CircleAlert,
  CircleCheckBig,
  CircleHelp,
  CircleMinusIcon,
  CircleStop,
  CircleX,
  ClipboardList,
  ClipboardPlusIcon,
  ClipboardX,
  Clock,
  Cloud,
  CloudDownload,
  CloudOff,
  Compass,
  Contact2Icon,
  Copy as CopyLuicide,
  CornerUpRight,
  DotIcon,
  Download,
  ExternalLink,
  Eye,
  EyeOff,
  File,
  FileUp,
  Filter,
  FlagIcon,
  Globe,
  HandHeartIcon,
  HelpCircle,
  Home,
  Hospital,
  Hotel,
  Hourglass,
  Image,
  Info,
  Key,
  Languages,
  Link,
  List,
  ListPlusIcon,
  Loader,
  Lock as LockLuicide,
  LockOpen,
  LogOut,
  Mail,
  Map,
  MapPin,
  Menu,
  MessageCircle,
  MessageSquare,
  Monitor,
  MoreVertical,
  Move,
  NotepadTextIcon,
  Paperclip,
  Pause,
  PencilIcon,
  PersonStanding,
  Phone as PhoneLuicide,
  Play,
  Plus,
  PlusCircle,
  Printer,
  PrinterIcon,
  RefreshCcw,
  RotateCw,
  Save,
  Search as SearchLuicide,
  Send,
  Settings,
  Share,
  ShieldCheck,
  ShoppingBag,
  Smartphone,
  Smile,
  Square,
  SquarePenIcon,
  SquareUserRound,
  Star,
  ThumbsDown,
  ThumbsUp,
  Trash2Icon,
  TriangleAlert,
  Undo,
  Upload,
  User,
  UserMinus,
  UserPlus,
  Users,
  Weight,
  X,
  XCircle,
} from "lucide-react";

export type IconType =
  | ValueOf<typeof icons>["new"]
  | ValueOf<typeof icons>["old"];

export const icons = {
  AccessibilityNew: { old: AccessibilityNewMui, new: PersonStanding },
  AccessibleForwardIcon: { old: AccessibleForwardIconMui, new: PersonStanding },
  AccessTimeIcon: { old: AccessTimeIconMui, new: Clock },
  AccountIcon: { old: AccountIconMui, new: User },
  AddIcon: { old: AddIconMui, new: Plus },
  AddressIcon: { old: AddressIconMui, new: MapPin },
  AdminIcon: { old: AdminIconMui, new: Key },
  AgeIcon: { old: AgeIconMui, new: CalendarDays },
  AlarmIcon: { old: AlarmIconMui, new: AlarmClock },
  AlarmOnIcon: { old: AlarmOnIconMui, new: AlarmCheck },
  ArchiveIcon: { old: ArchiveIconMui, new: Archive },
  ArchiveOutlinedIcon: { old: ArchiveOutlinedIconMui, new: Archive },
  ArrowBackIcon: { old: ArrowBackIconMui, new: ArrowLeftLuicide },
  ArrowBackIosIcon: { old: ArrowBackIosIconMui, new: ChevronLeftLuicide },
  ArrowDown: { old: ArrowDownMui, new: ChevronDown },
  ArrowDownwardIcon: { old: ArrowDownwardIconMui, new: ArrowDownLuicide },
  ArrowForwardIosSharpIcon: {
    old: ArrowForwardIosSharpIconMui,
    new: ChevronRightLuicide,
  },
  CreateIcon: { old: CreateIconMui, new: SquarePenIcon },
  ArrowLeftIcon: { old: ArrowLeftIconMui, new: ChevronLeftLuicide },
  ArrowRightIcon: { old: ArrowRightIconMui, new: ChevronRightLuicide },
  ArrowUp: { old: ArrowUpMui, new: ChevronUp },
  AssignmentIcon: { old: AssignmentIconMui, new: ClipboardList },
  AttachFile: { old: AttachFileMui, new: Paperclip },
  AutorenewIcon: { old: AutorenewIconMui, new: RefreshCcw },
  BadgeOutlinedIcon: { old: BadgeOutlinedIconMui, new: ClipboardPlusIcon },
  BlockIcon: { old: BlockIconMui, new: Ban },
  BorderOuter: { old: BorderOuterMui, new: Square },
  BusinessIcon: { old: BusinessIconMui, new: Building },
  CachedIcon: { old: CachedIconMui, new: RotateCw },
  CancelIcon: { old: CancelIconMui, new: XCircle },
  CareproviderIcon: { old: CareproviderIconMui, new: Hotel },
  ChatIcon: { old: ChatIconMui, new: MessageCircle },
  CheckCircleIcon: { old: CheckCircleIconMui, new: CheckCircleLucide },
  CheckCircleOutlineIcon: {
    old: CheckCircleOutlineIconMui,
    new: CheckCircleLucide,
  },
  CheckCircleRoundedIcon: {
    old: CheckCircleRoundedIconMui,
    new: CheckCircleLucide,
  },
  CheckIcon: { old: CheckIconMui, new: Check },
  ChevronLeft: { old: ChevronLeftMui, new: ChevronLeftLuicide },
  ChevronRight: { old: ChevronRightMui, new: ChevronRightLuicide },
  ChevronRightIcon: { old: ChevronRightIconMui, new: ChevronRightLuicide },
  CircleIcon: { old: CircleIconMui, new: DotIcon },
  ClearIcon: { old: ClearIconMui, new: X },
  ClinicIcon: { old: ClinicIconMui, new: Hospital },
  CloseIcon: { old: CloseIconMui, new: X },
  CloseOutlinedIcon: { old: CloseOutlinedIconMui, new: X },
  CloseRoundedIcon: { old: CloseRoundedIconMui, new: X },
  CloudDoneIcon: { old: CloudDoneIconMui, new: Cloud },
  CloudDownloadIcon: { old: CloudDownloadIconMui, new: CloudDownload },
  CloudOffIcon: { old: CloudOffIconMui, new: CloudOff },
  ContactIcon: { old: ContactIconMui, new: User },
  ContactMail: { old: ContactMailMui, new: Mail },
  ContentCopyIcon: { old: ContentCopyIconMui, new: CopyLuicide },
  ContentPasteOffIcon: { old: ContentPasteOffIconMui, new: ClipboardX },
  ControlPointIcon: { old: ControlPointIconMui, new: PlusCircle },
  CopyIcon: { old: CopyMui, new: CopyLuicide },
  DateRangeIcon: { old: DateRangeIconMui, new: CalendarDays },
  DeleteIcon: { old: DeleteIconMui, new: Trash2Icon },
  DeleteOutlinedIcon: { old: DeleteOutlinedIconMui, new: Trash2Icon },
  DescriptionIcon: { old: DescriptionIconMui, new: BookOpenTextIcon },
  DescriptionOutlinedIcon: {
    old: DescriptionOutlinedIconMui,
    new: ClipboardList,
  },
  DirectionsOutlinedIcon: { old: DirectionsOutlined, new: CornerUpRight },
  DoneIcon: { old: DoneIconMui, new: Check },
  DoneOutlinedIcon: { old: DoneOutlinedIconMui, new: Check },
  DoNotDisturbOnIcon: { old: DoNotDisturbOnMui, new: CircleMinusIcon },
  DownloadIcon: { old: DownloadIconMui, new: Download },
  DraftsIcon: { old: DraftsIconMui, new: File },
  DragIndicator: { old: DragIndicatorMui, new: Move },
  DvrIcon: { old: DvrIconMui, new: Monitor },
  EditIcon: { old: EditIconMui, new: PencilIcon },
  EmailIcon: { old: EmailMui, new: Mail },
  ErrorIcon: { old: ErrorIconMui, new: CircleAlert },
  ErrorOutlineIcon: { old: ErrorOutlineIconMui, new: CircleAlert },
  ExpandLessIcon: { old: ExpandLessIconMui, new: ChevronUp },
  ExpandMoreIcon: { old: ExpandMoreIconMui, new: ChevronDown },
  ExploreIcon: { old: ExploreIconMui, new: Compass },
  ExtensionIcon: { old: ExtensionIconMui, new: BlocksIcon },
  FaceIcon: { old: FaceIconMui, new: Smile },
  FailureIcon: { old: ThumbDownAltMui, new: ThumbsDown },
  FaxIcon: { old: PrintMui, new: PrinterIcon },
  FileCopyIcon: { old: FileCopyIconMui, new: CopyLuicide },
  FileCopyOutlinedIcon: { old: FileCopyOutlinedIconMui, new: CopyLuicide },
  FileUploadOutlined: { old: FileUploadOutlinedMui, new: Upload },
  FilterListIcon: { old: FilterListIconMui, new: Filter },
  FiltersCloseIcon: { old: FiltersCloseIconMui, new: X },
  FindInPageIcon: { old: FindInPageIconMui, new: SearchLuicide },
  OutlinedFlagIcon: { old: OutlinedFlagMui, new: FlagIcon },
  FormatListBulletedIcon: { old: FormatListBulletedIconMui, new: List },
  GetAppIcon: { old: GetAppIconMui, new: Download },
  GroupIcon: { old: GroupIconMui, new: Users },
  HelpIcon: { old: HelpIconMui, new: HelpCircle },
  HelpOutline: { old: HelpOutlineMui, new: HelpCircle },
  HighlightOffIcon: { old: HighlightOffIconMui, new: CircleX },
  HistoryEduIcon: { old: HistoryEduIconMui, new: NotepadTextIcon },
  HomeIcon: { old: HomeIconMui, new: Home },
  HotelIcon: { old: HotelIconMui, new: Bed },
  HourglassEmptyIcon: { old: HourglassEmptyIconMui, new: Hourglass },
  HourGlassFullIcon: { old: HourGlassFullIconMui, new: Hourglass },
  InfoIcon: { old: InfoIconMui, new: Info },
  InfoOutlinedIcon: { old: InfoOutlinedMui, new: Info },
  InsertEmoticonIcon: { old: InsertEmoticonIconMui, new: Smile },
  InsertInvitationIcon: { old: InsertInvitationIconMui, new: CalendarDays },
  InsurancesIcon: { old: LocalHospitalMui, new: Hospital },
  IosShareIcon: { old: IosShareIconMui, new: Share },
  KeyboardArrowDown: { old: KeyboardArrowDownMui, new: ArrowDownLuicide },
  KeyboardArrowRightIcon: {
    old: KeyboardArrowRightIconMui,
    new: ChevronRightLuicide,
  },
  KeyboardBackspaceIcon: {
    old: KeyboardBackspaceIconMui,
    new: ArrowLeftLuicide,
  },
  Language: { old: LanguageMui, new: Globe },
  LaunchIcon: { old: LaunchIconMui, new: ExternalLink },
  LinkIcon: { old: LinkIconMui, new: Link },
  LocalHospital: { old: LocalHospitalMui, new: Hospital },
  LocalMallIcon: { old: LocalMallIconMui, new: ShoppingBag },
  LocalPrintIcon: { old: LocalPrintIconMui, new: Printer },
  LocalPrintshopOutlinedIcon: {
    old: LocalPrintshopOutlinedIconMui,
    new: Printer,
  },
  LocationCity: { old: LocationCityMui, new: Building2Icon },
  LocationOnIcon: { old: LocationOnIconMui, new: MapPin },
  LocationSearching: { old: LocationSearchingMui, new: SearchLuicide },
  Lock: { old: LockMui, new: LockLuicide },
  LockIcon: { old: LockIconMui, new: LockLuicide },
  LogoutIcon: { old: LogoutIconMui, new: LogOut },
  MailIcon: { old: MailMui, new: Mail },
  MailOutlineIcon: { old: MailOutlineIconMui, new: Mail },
  MapIcon: { old: MapIconMui, new: Map },
  MenuBookIcon: { old: MenuBookIconMui, new: BookOpenText },
  MenuIcon: { old: MenuIconMui, new: Menu },
  MessageIcon: { old: MessageIconMui, new: MessageCircle },
  MobilePhoneIcon: { old: MobilePhoneIconMui, new: Smartphone },
  ModeEditOutlineOutlinedIcon: {
    old: ModeEditOutlineOutlinedIconMui,
    new: PencilIcon,
  },
  MoreVertIcon: { old: MoreVertIconMui, new: MoreVertical },
  NavigateBeforeIcon: { old: NavigateBeforeIconMui, new: ArrowLeftLuicide },
  NavigateNextIcon: { old: NavigateNextIconMui, new: ArrowRightLuicide },
  NoEncryptionOutlinedIcon: { old: NoEncryptionOutlinedIconMui, new: LockOpen },
  NotificationsIcon: { old: NotificationsIconMui, new: Bell },
  NotInterestedIcon: { old: NotInterestedIconMui, new: Ban },
  OpenInNewIcon: { old: OpenInNewIconMui, new: ExternalLink },
  PauseIcon: { old: PauseIconMui, new: Pause },
  PendingIcon: { old: PendingIconMui, new: Loader },
  PermContactCalendarIcon: {
    old: PermContactCalendarIconMui,
    new: Contact2Icon,
  },
  PersonAdd: { old: PersonAddMui, new: UserPlus },
  PersonAddDisabledIcon: { old: PersonAddDisabledIconMui, new: UserMinus },
  PersonIcon: { old: PersonIconMui, new: User },
  PersonOutlineIcon: { old: PersonOutlineIconMui, new: User },
  Phone: { old: PhoneMui, new: PhoneLuicide },
  PhoneAndroidIcon: { old: PhoneAndroidIconMui, new: Smartphone },
  PhoneIcon: { old: PhoneIconMui, new: PhoneLuicide },
  PhotoIcon: { old: PhotoIconMui, new: Image },
  PlaceIcon: { old: PlaceIconMui, new: MapPin },
  PlayArrowIcon: { old: PlayArrowIconMui, new: Play },
  PlaylistAddCheckIcon: { old: PlaylistAddCheckIconMui, new: ListPlusIcon },
  PlaylistAddIcon: { old: PlaylistAddIconMui, new: ListPlusIcon },
  PrintIcon: { old: PrintMui, new: Printer },
  PriorityHighIcon: { old: PriorityHighIconMui, new: AlertOctagon },
  QueryBuilderIcon: { old: QueryBuilderIconMui, new: Clock },
  QuestionMarkIcon: { old: QuestionMarkIconMui, new: CircleHelp },
  RefreshIcon: { old: RefreshIconMui, new: RotateCw },
  RemoveRedEye: { old: RemoveRedEyeMui, new: Eye },
  ResetPasswordIcon: { old: EmailMui, new: Mail },
  RoomIcon: { old: RoomIconMui, new: MapPin },
  SaveIcon: { old: SaveIconMui, new: Save },
  SearchIcon: { old: SearchIconMui, new: SearchLuicide },
  SendIcon: { old: SendIconMui, new: Send },
  SentimentSatisfied: { old: SentimentSatisfiedMui, new: Smile },
  SettingsIcon: { old: SettingsIconMui, new: Settings },
  SmsIcon: { old: SmsIconMui, new: MessageSquare },
  StarBorderIcon: { old: StarBorderIconMui, new: Star },
  StarIcon: { old: StarIconMui, new: Star },
  StarRate: { old: StarRateMui, new: Star },
  StarsIcon: { old: StarsIconMui, new: Star },
  StartDateIcon: { old: EventMui, new: CalendarDays },
  StopIcon: { old: StopIconMui, new: CircleStop },
  SuccessIcon: { old: ThumbUpAltMui, new: ThumbsUp },
  SupervisorAccountIcon: { old: SupervisorAccountIconMui, new: Users },
  TaskAltIcon: { old: TaskAltIconMui, new: CircleCheckBig },
  ThumbDownAlt: { old: ThumbDownAltMui, new: ThumbsDown },
  ThumbUpAlt: { old: ThumbUpAltMui, new: ThumbsUp },
  TodayIcon: { old: TodayIconMui, new: CalendarDays },
  TranslateIcon: { old: TranslateIconMui, new: Languages },
  UndoIcon: { old: UndoIconMui, new: Undo },
  UploadFileIcon: { old: UploadFileMui, new: FileUp },
  UploadIcon: { old: UploadIconMui, new: Upload },
  VerifiedIcon: { old: VerifiedIconMui, new: ShieldCheck },
  VisibilityIcon: { old: VisibilityIconMui, new: Eye },
  VisibilityOff: { old: VisibilityOffMui, new: EyeOff },
  VolunteerActivismIcon: { old: VolunteerActivismIconMUI, new: HandHeartIcon },
  WarningAmberSharpIcon: { old: WarningAmberSharpMui, new: TriangleAlert },
  WarningIcon: { old: WarningIconMui, new: AlertTriangle },
  WeightIcon: { old: WeightIconMui, new: Weight },
  WelcomeIcon: { old: ContactMailMui, new: SquareUserRound },
};

export const AccessibilityNew = isNewTheme
  ? icons.AccessibilityNew.new
  : icons.AccessibilityNew.old;
export const AccessibleForwardIcon = isNewTheme
  ? icons.AccessibleForwardIcon.new
  : icons.AccessibleForwardIcon.old;
export const AccessTimeIcon = isNewTheme
  ? icons.AccessTimeIcon.new
  : icons.AccessTimeIcon.old;
export const AccountIcon = isNewTheme
  ? icons.AccountIcon.new
  : icons.AccountIcon.old;
export const AddIcon = isNewTheme ? icons.AddIcon.new : icons.AddIcon.old;
export const AddressIcon = isNewTheme
  ? icons.AddressIcon.new
  : icons.AddressIcon.old;
export const AdminIcon = isNewTheme ? icons.AdminIcon.new : icons.AdminIcon.old;
export const AgeIcon = isNewTheme ? icons.AgeIcon.new : icons.AgeIcon.old;
export const AlarmIcon = isNewTheme ? icons.AlarmIcon.new : icons.AlarmIcon.old;
export const AlarmOnIcon = isNewTheme
  ? icons.AlarmOnIcon.new
  : icons.AlarmOnIcon.old;
export const ArchiveIcon = isNewTheme
  ? icons.ArchiveIcon.new
  : icons.ArchiveIcon.old;
export const ArchiveOutlinedIcon = isNewTheme
  ? icons.ArchiveOutlinedIcon.new
  : icons.ArchiveOutlinedIcon.old;
export const ArrowBackIcon = isNewTheme
  ? icons.ArrowBackIcon.new
  : icons.ArrowBackIcon.old;
export const ArrowBackIosIcon = isNewTheme
  ? icons.ArrowBackIosIcon.new
  : icons.ArrowBackIosIcon.old;
export const ArrowDown = isNewTheme ? icons.ArrowDown.new : icons.ArrowDown.old;
export const ArrowDownwardIcon = isNewTheme
  ? icons.ArrowDownwardIcon.new
  : icons.ArrowDownwardIcon.old;
export const ArrowForwardIosSharpIcon = isNewTheme
  ? icons.ArrowForwardIosSharpIcon.new
  : icons.ArrowForwardIosSharpIcon.old;
export const ArrowLeftIcon = isNewTheme
  ? icons.ArrowLeftIcon.new
  : icons.ArrowLeftIcon.old;
export const ArrowRightIcon = isNewTheme
  ? icons.ArrowRightIcon.new
  : icons.ArrowRightIcon.old;
export const ArrowUp = isNewTheme ? icons.ArrowUp.new : icons.ArrowUp.old;
export const AssignmentIcon = isNewTheme
  ? icons.AssignmentIcon.new
  : icons.AssignmentIcon.old;
export const AttachFile = isNewTheme
  ? icons.AttachFile.new
  : icons.AttachFile.old;
export const AutorenewIcon = isNewTheme
  ? icons.AutorenewIcon.new
  : icons.AutorenewIcon.old;
export const BadgeOutlinedIcon = isNewTheme
  ? icons.BadgeOutlinedIcon.new
  : icons.BadgeOutlinedIcon.old;
export const BlockIcon = isNewTheme ? icons.BlockIcon.new : icons.BlockIcon.old;
export const BorderOuter = isNewTheme
  ? icons.BorderOuter.new
  : icons.BorderOuter.old;
export const BusinessIcon = isNewTheme
  ? icons.BusinessIcon.new
  : icons.BusinessIcon.old;
export const CachedIcon = isNewTheme
  ? icons.CachedIcon.new
  : icons.CachedIcon.old;
export const CancelIcon = isNewTheme
  ? icons.CancelIcon.new
  : icons.CancelIcon.old;
export const CareproviderIcon = isNewTheme
  ? icons.CareproviderIcon.new
  : icons.CareproviderIcon.old;
export const ChatIcon = isNewTheme ? icons.ChatIcon.new : icons.ChatIcon.old;
export const CheckCircleIcon = isNewTheme
  ? icons.CheckCircleIcon.new
  : icons.CheckCircleIcon.old;
export const CheckCircleOutlineIcon = isNewTheme
  ? icons.CheckCircleOutlineIcon.new
  : icons.CheckCircleOutlineIcon.old;
export const CheckCircleRoundedIcon = isNewTheme
  ? icons.CheckCircleRoundedIcon.new
  : icons.CheckCircleRoundedIcon.old;
export const CheckIcon = isNewTheme ? icons.CheckIcon.new : icons.CheckIcon.old;
export const ChevronLeft = isNewTheme
  ? icons.ChevronLeft.new
  : icons.ChevronLeft.old;
export const ChevronRight = isNewTheme
  ? icons.ChevronRight.new
  : icons.ChevronRight.old;
export const ChevronRightIcon = isNewTheme
  ? icons.ChevronRightIcon.new
  : icons.ChevronRightIcon.old;
export const CircleIcon = isNewTheme
  ? icons.CircleIcon.new
  : icons.CircleIcon.old;
export const ClearIcon = isNewTheme ? icons.ClearIcon.new : icons.ClearIcon.old;
export const ClinicIcon = isNewTheme
  ? icons.ClinicIcon.new
  : icons.ClinicIcon.old;
export const CloseIcon = isNewTheme ? icons.CloseIcon.new : icons.CloseIcon.old;
export const CloseOutlinedIcon = isNewTheme
  ? icons.CloseOutlinedIcon.new
  : icons.CloseOutlinedIcon.old;
export const CloseRoundedIcon = isNewTheme
  ? icons.CloseRoundedIcon.new
  : icons.CloseRoundedIcon.old;
export const CloudDoneIcon = isNewTheme
  ? icons.CloudDoneIcon.new
  : icons.CloudDoneIcon.old;
export const CloudDownloadIcon = isNewTheme
  ? icons.CloudDownloadIcon.new
  : icons.CloudDownloadIcon.old;
export const CloudOffIcon = isNewTheme
  ? icons.CloudOffIcon.new
  : icons.CloudOffIcon.old;
export const ContactIcon = isNewTheme
  ? icons.ContactIcon.new
  : icons.ContactIcon.old;
export const ContactMail = isNewTheme
  ? icons.ContactMail.new
  : icons.ContactMail.old;
export const ContentCopyIcon = isNewTheme
  ? icons.ContentCopyIcon.new
  : icons.ContentCopyIcon.old;
export const ContentPasteOffIcon = isNewTheme
  ? icons.ContentPasteOffIcon.new
  : icons.ContentPasteOffIcon.old;
export const ControlPointIcon = isNewTheme
  ? icons.ControlPointIcon.new
  : icons.ControlPointIcon.old;
export const CopyIcon = isNewTheme ? icons.CopyIcon.new : icons.CopyIcon.old;
export const CreateIcon = isNewTheme
  ? icons.CreateIcon.new
  : icons.CreateIcon.old;
export const DateRangeIcon = isNewTheme
  ? icons.DateRangeIcon.new
  : icons.DateRangeIcon.old;
export const DeleteIcon = isNewTheme
  ? icons.DeleteIcon.new
  : icons.DeleteIcon.old;
export const DeleteOutlinedIcon = isNewTheme
  ? icons.DeleteOutlinedIcon.new
  : icons.DeleteOutlinedIcon.old;
export const DescriptionIcon = isNewTheme
  ? icons.DescriptionIcon.new
  : icons.DescriptionIcon.old;
export const DescriptionOutlinedIcon = isNewTheme
  ? icons.DescriptionOutlinedIcon.new
  : icons.DescriptionOutlinedIcon.old;
export const DirectionsOutlinedIcon = isNewTheme
  ? icons.DirectionsOutlinedIcon.new
  : icons.DirectionsOutlinedIcon.old;
export const DoneIcon = isNewTheme ? icons.DoneIcon.new : icons.DoneIcon.old;
export const DoneOutlinedIcon = isNewTheme
  ? icons.DoneOutlinedIcon.new
  : icons.DoneOutlinedIcon.old;
export const DoNotDisturbOnIcon = isNewTheme
  ? icons.DoNotDisturbOnIcon.new
  : icons.DoNotDisturbOnIcon.old;
export const DownloadIcon = isNewTheme
  ? icons.DownloadIcon.new
  : icons.DownloadIcon.old;
export const DraftsIcon = isNewTheme
  ? icons.DraftsIcon.new
  : icons.DraftsIcon.old;
export const DragIndicator = isNewTheme
  ? icons.DragIndicator.new
  : icons.DragIndicator.old;
export const DvrIcon = isNewTheme ? icons.DvrIcon.new : icons.DvrIcon.old;
export const EditIcon = isNewTheme ? icons.EditIcon.new : icons.EditIcon.old;
export const EmailIcon = isNewTheme ? icons.EmailIcon.new : icons.EmailIcon.old;
export const ErrorIcon = isNewTheme ? icons.ErrorIcon.new : icons.ErrorIcon.old;
export const ErrorOutlineIcon = isNewTheme
  ? icons.ErrorOutlineIcon.new
  : icons.ErrorOutlineIcon.old;
export const ExpandLessIcon = isNewTheme
  ? icons.ExpandLessIcon.new
  : icons.ExpandLessIcon.old;
export const ExpandMoreIcon = isNewTheme
  ? icons.ExpandMoreIcon.new
  : icons.ExpandMoreIcon.old;
export const ExploreIcon = isNewTheme
  ? icons.ExploreIcon.new
  : icons.ExploreIcon.old;
export const ExtensionIcon = isNewTheme
  ? icons.ExtensionIcon.new
  : icons.ExtensionIcon.old;
export const FaceIcon = isNewTheme ? icons.FaceIcon.new : icons.FaceIcon.old;
export const FailureIcon = isNewTheme
  ? icons.FailureIcon.new
  : icons.FailureIcon.old;
export const FaxIcon = isNewTheme ? icons.FaxIcon.new : icons.FaxIcon.old;
export const FileCopyIcon = isNewTheme
  ? icons.FileCopyIcon.new
  : icons.FileCopyIcon.old;
export const FileCopyOutlinedIcon = isNewTheme
  ? icons.FileCopyOutlinedIcon.new
  : icons.FileCopyOutlinedIcon.old;
export const FileUploadOutlined = isNewTheme
  ? icons.FileUploadOutlined.new
  : icons.FileUploadOutlined.old;
export const FilterListIcon = isNewTheme
  ? icons.FilterListIcon.new
  : icons.FilterListIcon.old;
export const FiltersCloseIcon = isNewTheme
  ? icons.FiltersCloseIcon.new
  : icons.FiltersCloseIcon.old;
export const FindInPageIcon = isNewTheme
  ? icons.FindInPageIcon.new
  : icons.FindInPageIcon.old;
export const FormatListBulletedIcon = isNewTheme
  ? icons.FormatListBulletedIcon.new
  : icons.FormatListBulletedIcon.old;
export const GetAppIcon = isNewTheme
  ? icons.GetAppIcon.new
  : icons.GetAppIcon.old;
export const GroupIcon = isNewTheme ? icons.GroupIcon.new : icons.GroupIcon.old;
export const HelpIcon = isNewTheme ? icons.HelpIcon.new : icons.HelpIcon.old;
export const HelpOutline = isNewTheme
  ? icons.HelpOutline.new
  : icons.HelpOutline.old;
export const HighlightOffIcon = isNewTheme
  ? icons.HighlightOffIcon.new
  : icons.HighlightOffIcon.old;
export const HistoryEduIcon = isNewTheme
  ? icons.HistoryEduIcon.new
  : icons.HistoryEduIcon.old;
export const HomeIcon = isNewTheme ? icons.HomeIcon.new : icons.HomeIcon.old;
export const HotelIcon = isNewTheme ? icons.HotelIcon.new : icons.HotelIcon.old;
export const HourglassEmptyIcon = isNewTheme
  ? icons.HourglassEmptyIcon.new
  : icons.HourglassEmptyIcon.old;
export const HourGlassFullIcon = isNewTheme
  ? icons.HourGlassFullIcon.new
  : icons.HourGlassFullIcon.old;
export const InfoIcon = isNewTheme ? icons.InfoIcon.new : icons.InfoIcon.old;
export const InfoOutlinedIcon = isNewTheme
  ? icons.InfoOutlinedIcon.new
  : icons.InfoOutlinedIcon.old;
export const InsertEmoticonIcon = isNewTheme
  ? icons.InsertEmoticonIcon.new
  : icons.InsertEmoticonIcon.old;
export const InsertInvitationIcon = isNewTheme
  ? icons.InsertInvitationIcon.new
  : icons.InsertInvitationIcon.old;
export const InsurancesIcon = isNewTheme
  ? icons.InsurancesIcon.new
  : icons.InsurancesIcon.old;
export const IosShareIcon = isNewTheme
  ? icons.IosShareIcon.new
  : icons.IosShareIcon.old;
export const KeyboardArrowDown = isNewTheme
  ? icons.KeyboardArrowDown.new
  : icons.KeyboardArrowDown.old;
export const KeyboardArrowRightIcon = isNewTheme
  ? icons.KeyboardArrowRightIcon.new
  : icons.KeyboardArrowRightIcon.old;
export const KeyboardBackspaceIcon = isNewTheme
  ? icons.KeyboardBackspaceIcon.new
  : icons.KeyboardBackspaceIcon.old;
export const Language = isNewTheme ? icons.Language.new : icons.Language.old;
export const LaunchIcon = isNewTheme
  ? icons.LaunchIcon.new
  : icons.LaunchIcon.old;
export const LinkIcon = isNewTheme ? icons.LinkIcon.new : icons.LinkIcon.old;
export const LocalHospital = isNewTheme
  ? icons.LocalHospital.new
  : icons.LocalHospital.old;
export const LocalMallIcon = isNewTheme
  ? icons.LocalMallIcon.new
  : icons.LocalMallIcon.old;
export const LocalPrintIcon = isNewTheme
  ? icons.LocalPrintIcon.new
  : icons.LocalPrintIcon.old;
export const LocalPrintshopOutlinedIcon = isNewTheme
  ? icons.LocalPrintshopOutlinedIcon.new
  : icons.LocalPrintshopOutlinedIcon.old;
export const LocationCity = isNewTheme
  ? icons.LocationCity.new
  : icons.LocationCity.old;
export const LocationOnIcon = isNewTheme
  ? icons.LocationOnIcon.new
  : icons.LocationOnIcon.old;
export const LocationSearching = isNewTheme
  ? icons.LocationSearching.new
  : icons.LocationSearching.old;
export const Lock = isNewTheme ? icons.Lock.new : icons.Lock.old;
export const LockIcon = isNewTheme ? icons.LockIcon.new : icons.LockIcon.old;
export const LogoutIcon = isNewTheme
  ? icons.LogoutIcon.new
  : icons.LogoutIcon.old;
export const MailIcon = isNewTheme ? icons.MailIcon.new : icons.MailIcon.old;
export const MailOutlineIcon = isNewTheme
  ? icons.MailOutlineIcon.new
  : icons.MailOutlineIcon.old;
export const MapIcon = isNewTheme ? icons.MapIcon.new : icons.MapIcon.old;
export const MenuBookIcon = isNewTheme
  ? icons.MenuBookIcon.new
  : icons.MenuBookIcon.old;
export const MenuIcon = isNewTheme ? icons.MenuIcon.new : icons.MenuIcon.old;
export const MessageIcon = isNewTheme
  ? icons.MessageIcon.new
  : icons.MessageIcon.old;
export const MobilePhoneIcon = isNewTheme
  ? icons.MobilePhoneIcon.new
  : icons.MobilePhoneIcon.old;
export const ModeEditOutlineOutlinedIcon = isNewTheme
  ? icons.ModeEditOutlineOutlinedIcon.new
  : icons.ModeEditOutlineOutlinedIcon.old;
export const MoreVertIcon = isNewTheme
  ? icons.MoreVertIcon.new
  : icons.MoreVertIcon.old;
export const NavigateBeforeIcon = isNewTheme
  ? icons.NavigateBeforeIcon.new
  : icons.NavigateBeforeIcon.old;
export const NavigateNextIcon = isNewTheme
  ? icons.NavigateNextIcon.new
  : icons.NavigateNextIcon.old;
export const NoEncryptionOutlinedIcon = isNewTheme
  ? icons.NoEncryptionOutlinedIcon.new
  : icons.NoEncryptionOutlinedIcon.old;
export const NotificationsIcon = isNewTheme
  ? icons.NotificationsIcon.new
  : icons.NotificationsIcon.old;
export const NotInterestedIcon = isNewTheme
  ? icons.NotInterestedIcon.new
  : icons.NotInterestedIcon.old;
export const OpenInNewIcon = isNewTheme
  ? icons.OpenInNewIcon.new
  : icons.OpenInNewIcon.old;
export const OutlinedFlagIcon = isNewTheme
  ? icons.OutlinedFlagIcon.new
  : icons.OutlinedFlagIcon.old;
export const PauseIcon = isNewTheme ? icons.PauseIcon.new : icons.PauseIcon.old;
export const PendingIcon = isNewTheme
  ? icons.PendingIcon.new
  : icons.PendingIcon.old;
export const PermContactCalendarIcon = isNewTheme
  ? icons.PermContactCalendarIcon.new
  : icons.PermContactCalendarIcon.old;
export const PersonAdd = isNewTheme ? icons.PersonAdd.new : icons.PersonAdd.old;
export const PersonAddDisabledIcon = isNewTheme
  ? icons.PersonAddDisabledIcon.new
  : icons.PersonAddDisabledIcon.old;
export const PersonIcon = isNewTheme
  ? icons.PersonIcon.new
  : icons.PersonIcon.old;
export const PersonOutlineIcon = isNewTheme
  ? icons.PersonOutlineIcon.new
  : icons.PersonOutlineIcon.old;
export const Phone = isNewTheme ? icons.Phone.new : icons.Phone.old;
export const PhoneAndroidIcon = isNewTheme
  ? icons.PhoneAndroidIcon.new
  : icons.PhoneAndroidIcon.old;
export const PhoneIcon = isNewTheme ? icons.PhoneIcon.new : icons.PhoneIcon.old;
export const PhotoIcon = isNewTheme ? icons.PhotoIcon.new : icons.PhotoIcon.old;
export const PlaceIcon = isNewTheme ? icons.PlaceIcon.new : icons.PlaceIcon.old;
export const PlayArrowIcon = isNewTheme
  ? icons.PlayArrowIcon.new
  : icons.PlayArrowIcon.old;
export const PlaylistAddCheckIcon = isNewTheme
  ? icons.PlaylistAddCheckIcon.new
  : icons.PlaylistAddCheckIcon.old;
export const PlaylistAddIcon = isNewTheme
  ? icons.PlaylistAddIcon.new
  : icons.PlaylistAddIcon.old;
export const PrintIcon = isNewTheme ? icons.PrintIcon.new : icons.PrintIcon.old;
export const PriorityHighIcon = isNewTheme
  ? icons.PriorityHighIcon.new
  : icons.PriorityHighIcon.old;
export const QueryBuilderIcon = isNewTheme
  ? icons.QueryBuilderIcon.new
  : icons.QueryBuilderIcon.old;
export const QuestionMarkIcon = isNewTheme
  ? icons.QuestionMarkIcon.new
  : icons.QuestionMarkIcon.old;
export const RefreshIcon = isNewTheme
  ? icons.RefreshIcon.new
  : icons.RefreshIcon.old;
export const RemoveRedEye = isNewTheme
  ? icons.RemoveRedEye.new
  : icons.RemoveRedEye.old;
export const ResetPasswordIcon = isNewTheme
  ? icons.ResetPasswordIcon.new
  : icons.ResetPasswordIcon.old;
export const RoomIcon = isNewTheme ? icons.RoomIcon.new : icons.RoomIcon.old;
export const SaveIcon = isNewTheme ? icons.SaveIcon.new : icons.SaveIcon.old;
export const SearchIcon = isNewTheme
  ? icons.SearchIcon.new
  : icons.SearchIcon.old;
export const SendIcon = isNewTheme ? icons.SendIcon.new : icons.SendIcon.old;
export const SentimentSatisfied = isNewTheme
  ? icons.SentimentSatisfied.new
  : icons.SentimentSatisfied.old;
export const SettingsIcon = isNewTheme
  ? icons.SettingsIcon.new
  : icons.SettingsIcon.old;
export const SmsIcon = isNewTheme ? icons.SmsIcon.new : icons.SmsIcon.old;
export const StarBorderIcon = isNewTheme
  ? icons.StarBorderIcon.new
  : icons.StarBorderIcon.old;
export const StarIcon = isNewTheme ? icons.StarIcon.new : icons.StarIcon.old;
export const StarRate = isNewTheme ? icons.StarRate.new : icons.StarRate.old;
export const StarsIcon = isNewTheme ? icons.StarsIcon.new : icons.StarsIcon.old;
export const StartDateIcon = isNewTheme
  ? icons.StartDateIcon.new
  : icons.StartDateIcon.old;
export const StopIcon = isNewTheme ? icons.StopIcon.new : icons.StopIcon.old;
export const SuccessIcon = isNewTheme
  ? icons.SuccessIcon.new
  : icons.SuccessIcon.old;
export const SupervisorAccountIcon = isNewTheme
  ? icons.SupervisorAccountIcon.new
  : icons.SupervisorAccountIcon.old;
export const TaskAltIcon = isNewTheme
  ? icons.TaskAltIcon.new
  : icons.TaskAltIcon.old;
export const ThumbDownAlt = isNewTheme
  ? icons.ThumbDownAlt.new
  : icons.ThumbDownAlt.old;
export const ThumbUpAlt = isNewTheme
  ? icons.ThumbUpAlt.new
  : icons.ThumbUpAlt.old;
export const TodayIcon = isNewTheme ? icons.TodayIcon.new : icons.TodayIcon.old;
export const TranslateIcon = isNewTheme
  ? icons.TranslateIcon.new
  : icons.TranslateIcon.old;
export const UndoIcon = isNewTheme ? icons.UndoIcon.new : icons.UndoIcon.old;
export const UploadFileIcon = isNewTheme
  ? icons.UploadFileIcon.new
  : icons.UploadFileIcon.old;
export const UploadIcon = isNewTheme
  ? icons.UploadIcon.new
  : icons.UploadIcon.old;
export const VerifiedIcon = isNewTheme
  ? icons.VerifiedIcon.new
  : icons.VerifiedIcon.old;
export const VisibilityIcon = isNewTheme
  ? icons.VisibilityIcon.new
  : icons.VisibilityIcon.old;
export const VisibilityOff = isNewTheme
  ? icons.VisibilityOff.new
  : icons.VisibilityOff.old;
export const VolunteerActivismIcon = isNewTheme
  ? icons.VolunteerActivismIcon.new
  : icons.VolunteerActivismIcon.old;
export const WarningAmberSharpIcon = isNewTheme
  ? icons.WarningAmberSharpIcon.new
  : icons.WarningAmberSharpIcon.old;
export const WarningIcon = isNewTheme
  ? icons.WarningIcon.new
  : icons.WarningIcon.old;
export const WeightIcon = isNewTheme
  ? icons.WeightIcon.new
  : icons.WeightIcon.old;
export const WelcomeIcon = isNewTheme
  ? icons.WelcomeIcon.new
  : icons.WelcomeIcon.old;
