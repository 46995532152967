import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useEnvContext } from "context/EnvContext";
import { APP_CAREPROVIDER, TRACK_EVENTS } from "core/consts";
import { getKey } from "core/model/utils/strings";
import { AuctionRequest, Event } from "core/types";
import { PrintButtonConfirmedSchema } from "core/validationSchemas/tracking";
import { format, fromUnixTime } from "date-fns";
import { PrintIcon } from "ds/icons";
import { CheckboxInputFieldPresenter } from "ds_legacy/components/CheckboxInputField";
import RSButton from "ds_legacy/components/RSButton";
import { ICON_DARK } from "ds_legacy/materials/colors";
import { Divider, VerticalLayout } from "ds_legacy/materials/layouts";
import { margin, sizing } from "ds_legacy/materials/metrics";
import { Body, FONT_SIZE_20, Title } from "ds_legacy/materials/typography";
import { EventSpecMessage } from "dsl/atoms/EventSpecs";
import { useGetEventsSpecs } from "dsl/atoms/EventSpecs/utils";
import { usePrintWindow } from "dsl/atoms/PrintButton";
import { useModal, useQueryParam } from "dsl/hooks";
import EventCardCareprovider from "dsl/molecules/Messenger/EventCard/EventCardCareprovider";
import EventCardCareseeker from "dsl/molecules/Messenger/EventCard/EventCardCareseeker";
import { MessengerSpecMessage } from "dsl/molecules/Messenger/EventCard/EventMessagingBubble";
import { Dispatch, Fragment, SetStateAction, useCallback } from "react";
import { useTracking } from "react-tracking";
import { useTranslations } from "translations";
import Translations from "translations/types";

export const PRINT_PARAM_PATIENT_INFO = "patientInfo" as const;
export const PRINT_PARAM_PROVIDER_INFO = "providerInfo" as const;
export const PRINT_PARAM_CHAT_HISTORY = "chatHistory" as const;

export const PRINT_PARAM_KEYS = [
  PRINT_PARAM_PATIENT_INFO,
  PRINT_PARAM_PROVIDER_INFO,
  PRINT_PARAM_CHAT_HISTORY,
] as const;

export type PrintParamState = {
  [index in (typeof PRINT_PARAM_KEYS)[number]]?: boolean;
};

export const usePrintParams = () => {
  const { app } = useEnvContext();
  const isCareproviderApp = app === APP_CAREPROVIDER;
  const defaultState: PrintParamState = {
    [PRINT_PARAM_CHAT_HISTORY]: false,
  };
  if (isCareproviderApp) {
    defaultState[PRINT_PARAM_PATIENT_INFO] = true;
  } else {
    defaultState[PRINT_PARAM_PROVIDER_INFO] = true;
  }
  return {
    printParams: useQueryParam<PrintParamState>(defaultState),
    isCareproviderApp,
  };
};

export type PrintParams = [
  PrintParamState,
  Dispatch<SetStateAction<PrintParamState>>,
];

const PrintParamCheckBox = ({
  label,
  name,
  printParamState,
  setPrintParamState,
}: {
  label: string;
  name: (typeof PRINT_PARAM_KEYS)[number];
  printParamState: PrintParamState;
  setPrintParamState: Dispatch<SetStateAction<PrintParamState>>;
}) => {
  return (
    <CheckboxInputFieldPresenter
      value={!!printParamState?.[name]}
      onChange={(value: boolean) =>
        setPrintParamState((currentState) => ({
          ...currentState,
          [name]: value,
        }))
      }
      label={label}
    />
  );
};

export const PrintMessengerFab = ({
  careproviderName,
  printParams,
}: {
  careproviderName?: string | null | undefined;
  printParams: PrintParams;
}) => {
  const [open, setOpen] = useModal();
  const { app } = useEnvContext();
  const isCareproviderApp = app === APP_CAREPROVIDER;
  const [printParamState, setPrintParamState] = printParams;
  const { trackEvent } = useTracking<PrintButtonConfirmedSchema>();

  const translations = useTranslations();

  const closeForm = () => setOpen(false);

  const openPrintPreview = useCallback(() => {
    const uri = window.location.search;
    const prefix = uri.includes("?") ? "&" : "?";

    window.open(`${window.location}${prefix}print`, "_blank");
    setOpen(false);
  }, []);

  return (
    <>
      <IconButton
        onClick={() => {
          trackEvent({ name: TRACK_EVENTS.PRINT_BUTTON });
          setOpen(true);
        }}
        size="large"
      >
        <PrintIcon
          style={{ width: sizing(2.5), height: sizing(2.5), color: ICON_DARK }}
          size={FONT_SIZE_20}
        />
      </IconButton>
      <Dialog fullWidth open={open}>
        <DialogTitle>{translations.general.printModal.title}</DialogTitle>
        <DialogContent>
          <VerticalLayout>
            {isCareproviderApp ? (
              <PrintParamCheckBox
                name={PRINT_PARAM_PATIENT_INFO}
                printParamState={printParamState}
                setPrintParamState={setPrintParamState}
                label={translations.general.printModal.receiver.patientProfile}
              />
            ) : (
              <PrintParamCheckBox
                name={PRINT_PARAM_PROVIDER_INFO}
                printParamState={printParamState}
                setPrintParamState={setPrintParamState}
                label={translations.general.printModal.sender.informationAboutProvider(
                  { providerName: careproviderName || "" },
                )}
              />
            )}
            <PrintParamCheckBox
              name={PRINT_PARAM_CHAT_HISTORY}
              printParamState={printParamState}
              setPrintParamState={setPrintParamState}
              label={translations.general.printModal.sender.chatHistory}
            />
          </VerticalLayout>
        </DialogContent>
        <DialogActions>
          <RSButton
            className="cancel"
            color="primary"
            id="cancel"
            loading="na"
            onClick={closeForm}
            variant="text"
          >
            {translations.actions.cancel}
          </RSButton>
          <RSButton
            color="primary"
            disabled={!Object.values(printParamState || {}).some(Boolean)}
            id="confirm"
            loading="na"
            onClick={() => {
              trackEvent({
                name: TRACK_EVENTS.PRINT_BUTTON_CONFIRMED,
                print_chat_history: !!printParamState.chatHistory,
                print_profile_information: !!(isCareproviderApp
                  ? printParamState.patientInfo
                  : printParamState.providerInfo),
              });
              openPrintPreview();
            }}
            variant="text"
          >
            {translations.actions.confirm}
          </RSButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

function PrintMessageBubble({
  eventMessages,
  messagesToDisplay,
}: {
  eventMessages?: EventSpecMessage[];
  messagesToDisplay: string[] | null;
}) {
  return (
    <>
      {eventMessages?.map((msg, idx) => {
        return (
          <VerticalLayout margin={margin(1, 0, 0, 0)} key={idx?.toString()}>
            <MessengerSpecMessage eventMessage={msg} />
          </VerticalLayout>
        );
      })}
      {messagesToDisplay?.map((msg, i) => <Body key={getKey(i)}>{msg}</Body>)}
    </>
  );
}

export function PrintableEventCard({
  eventMessages,
  messagesToDisplay,
  statusHeader,
  timestamp,
}: {
  eventMessages?: EventSpecMessage[];
  messagesToDisplay: string[] | null;
  statusHeader: string | undefined;
  timestamp: number | null | undefined;
}) {
  return (
    <VerticalLayout
      style={{
        pageBreakInside: "avoid",
      }}
    >
      <VerticalLayout>
        <Body margin={margin(0)}>
          {timestamp && format(fromUnixTime(timestamp), "d LLLL y hh:mm")}
        </Body>
        <Body margin={margin(0)} whiteSpace="normal">
          {statusHeader}
        </Body>
      </VerticalLayout>
      <PrintMessageBubble
        eventMessages={eventMessages}
        messagesToDisplay={messagesToDisplay}
      />
      <Divider margin={margin(2, 0, 0, 0)} />
    </VerticalLayout>
  );
}

function PrintMessages({
  auctionRequest,
  events,
  forCareprovider,
}: {
  auctionRequest: AuctionRequest | undefined;
  events: Event[] | undefined;
  forCareprovider: boolean;
}) {
  const enhancedEvents = useGetEventsSpecs({
    auction: auctionRequest?.auction,
    auctionRequest,
    events,
    eventLocation: "messenger",
  });

  if (!auctionRequest || !enhancedEvents) return null;

  return (
    <>
      {enhancedEvents.map((event, index) => {
        return (
          <Fragment key={getKey(event.id, index)}>
            {forCareprovider ? (
              <EventCardCareprovider
                auctionRequest={auctionRequest}
                event={event}
                isDividerVisible={false}
                print
                shouldShowBefore={false}
              />
            ) : (
              <EventCardCareseeker
                auctionRequest={auctionRequest}
                event={event}
                isDividerVisible={false}
                print
                shouldShowBefore={false}
              />
            )}
          </Fragment>
        );
      })}
    </>
  );
}

export default function PrintMessenger({
  auctionRequest,
  didPrintInfo,
  encryptionError,
  events,
  forCareprovider,
  hasEncryption,
  translations,
  userSupportsEncryption,
}: {
  auctionRequest: AuctionRequest;
  didPrintInfo: boolean;
  encryptionError: string | undefined;
  events: Event[];
  forCareprovider: boolean;
  hasEncryption: boolean | undefined;
  translations: Translations;
  userSupportsEncryption: boolean | undefined;
}) {
  usePrintWindow(didPrintInfo);
  if (!hasEncryption || !userSupportsEncryption || encryptionError) {
    console.error("unable to print chat history\n", {
      hasEncryption,
      userSupportsEncryption,
      encryptionError,
    });
    return null;
  }

  return (
    <VerticalLayout
      style={{
        pageBreakInside: "avoid",
      }}
    >
      <Title>{translations.auctionRequest.messengerTab}</Title>
      <PrintMessages
        auctionRequest={auctionRequest}
        events={events}
        forCareprovider={forCareprovider}
      />
    </VerticalLayout>
  );
}
