import { dp } from "ds_legacy/materials/metrics";
import styled, { CSSProperties } from "styled-components";

export const Halo = styled.div<{
  backgroundColor?: CSSProperties["color"];
  border?: CSSProperties["border"];
  padding?: CSSProperties["padding"];
}>`
  border-radius: 100%;
  padding: ${({ padding }) => padding ?? dp(6)};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor ?? "unset"};
  border: ${({ border }) => border};
`;
