import Box from "@mui/material/Box";
import Tab, { TabTypeMap } from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { Theme, useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { styled as muiStyled } from "@mui/system";
import {
  AUCTION_STATUS_CANCELLED,
  AUCTION_STATUS_SUCCESS,
  PATIENT_TABS,
} from "core/consts";
import { isAuctionRunning } from "core/model/auctions";
import { Auction, Patient, TourElement } from "core/types";
import { DotWithBorder } from "ds_legacy/components/Dot";
import {
  BORDER_BOTTOM,
  DISABLED_COLOR,
  ERROR_COLOR,
  GREY_50,
  SUCCESS_COLOR,
  TEXT_STEPPER,
  WHITE,
} from "ds_legacy/materials/colors";
import {
  TABS_HEIGHT,
  border,
  dp,
  getPatientPageOffset,
} from "ds_legacy/materials/metrics";
import {
  Body,
  FONT_WEIGHT_BOLD,
  FONT_WEIGHT_MEDIUM,
} from "ds_legacy/materials/typography";
import { usePrint } from "dsl/atoms/Contexts";
import { useMedia } from "dsl/atoms/ResponsiveMedia";
import { useScrollToTop } from "dsl/hooks";
import { useCareseekerNavigationHandlers } from "dsl/hooks/useNavigationHandlers";
import { useClearSearchParams } from "dsl/hooks/useRemoveQueryParam";
import { PRINT_PARAM_KEYS } from "dsl/molecules/Messenger/PrintMessenger";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { getDotProps, useGetPatientTabConfigs } from "./helpers";

export type PatientTab = (typeof PATIENT_TABS)[number];

export type PatientTabConfig = TabTypeMap["props"] & {
  hide?: boolean;
  tour?: TourElement;
};

export const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  background-color: ${GREY_50};
`;

const useStyles = makeStyles(() => ({
  tabs: {
    width: "100%",
    position: "fixed",
    top: `${dp(160)}`,
    borderBottom: border({ color: BORDER_BOTTOM }),
    background: `${WHITE}`,
    zIndex: 40,
    minHeight: TABS_HEIGHT,
    height: TABS_HEIGHT,
    boxSizing: "border-box",
  },
  tab: {
    minHeight: TABS_HEIGHT,
    height: TABS_HEIGHT,
  },
}));

function a11yProps(value: PatientTabConfig["value"]) {
  return {
    id: `patient-tab-${value}`,
    "aria-controls": `simple-tabpanel-${value}`,
    "data-testid": `${value}-button`,
  };
}

function getAuctionStatusColor(
  auction: Auction | null | undefined,
  theme: Theme,
) {
  const newAnswersNumber = auction?.new_responses;

  const status = auction?.status;

  if (isAuctionRunning(status)) {
    if (newAnswersNumber && newAnswersNumber > 0)
      return theme.palette.secondary.main;
    return theme.palette.primary.main;
  }

  switch (status) {
    case AUCTION_STATUS_CANCELLED:
      return ERROR_COLOR;
    case AUCTION_STATUS_SUCCESS:
      return SUCCESS_COLOR;
    default:
      return null;
  }
}

const StyledTabs = muiStyled(Tabs)({
  width: "100%",
  position: "fixed",
  top: dp(160),
  borderBottom: border({ color: BORDER_BOTTOM }),
  background: WHITE,
  zIndex: 40,
  minHeight: TABS_HEIGHT,
  height: TABS_HEIGHT,
  boxSizing: "border-box",
});

const StyledTab = muiStyled(Tab)<{ isActive?: boolean }>(({ isActive }) => ({
  fontWeight: isActive ? FONT_WEIGHT_BOLD : FONT_WEIGHT_MEDIUM,
  textTransform: "capitalize",
  whiteSpace: "nowrap",
  opacity: 1,
}));

export default function PatientNavigation({
  auction,
  children,
  patient,
}: {
  auction: Auction;
  children: React.ReactNode;
  patient: Patient;
}) {
  useScrollToTop();
  useClearSearchParams(["activeTab", "recommendation", ...PRINT_PARAM_KEYS]);
  const classes = useStyles();
  const navigationHandlers = useCareseekerNavigationHandlers();
  const theme = useTheme();
  const { isDesktop } = useMedia();
  const print = usePrint();
  const patientId = patient.id;
  const params = useParams();
  const tabs = useGetPatientTabConfigs({ auction });

  const activeStep = useMemo(() => {
    const tabParam = params["*"]?.split("/")?.[0];
    return tabs.find(({ value }) => tabParam === value);
  }, [params["*"], tabs.length]);

  const { color } = useMemo(
    () => ({
      color: getAuctionStatusColor(auction, theme),
    }),
    [auction, theme],
  );

  const renderTab = (
    { disabled, hide, icon, label, tour, value }: PatientTabConfig,
    index: number,
  ) => {
    const isActive = value === activeStep?.value;

    const { dotColor, showDot } = getDotProps({
      color,
      value,
      disabled,
    });
    return !hide ? (
      <StyledTab
        key={index}
        value={value}
        label={
          <Box display="flex">
            <Body
              className={isActive ? "active" : undefined}
              margin="unset"
              color={
                disabled
                  ? DISABLED_COLOR
                  : isActive === true
                  ? theme.palette.primary.main
                  : TEXT_STEPPER
              }
              fontWeight={isActive === true ? "bold" : "normal"}
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
            >
              {label}
            </Body>
            {icon}
            {showDot && <DotWithBorder color={dotColor || undefined} />}
          </Box>
        }
        disabled={disabled}
        className={classes.tab}
        onClick={() =>
          navigationHandlers.patient.goToPatientHomeTab({
            auctionId: auction.id,
            patientId,
            tab: value,
          })
        }
        {...a11yProps(value)}
        {...tour}
      />
    ) : null;
  };

  return print ? (
    <>{children}</>
  ) : (
    <>
      <StyledTabs
        value={activeStep?.value}
        variant={!isDesktop ? "scrollable" : "fullWidth"}
        aria-label="Patient menu"
        scrollButtons={false}
        indicatorColor="primary"
      >
        {tabs.map((tab, index) => renderTab(tab, index))}
      </StyledTabs>
      {/* This element takes up the space of the tabs, since tabs has position:fixed */}
      <div style={{ height: TABS_HEIGHT, backgroundColor: GREY_50 }}></div>
      {tabs.map(({ value }, index) => {
        const isActive = value === activeStep?.value;
        return isActive ? (
          <Box
            key={index}
            aria-labelledby={`patient-tab-${value}`}
            boxSizing="border-box"
            data-testid={`patient-tabpanel-${value}`}
            id={`patient-tabpanel-${value}`}
            role="tabpanel"
            maxWidth="100%"
            bgcolor={GREY_50}
            minHeight={`calc(100vh - ${getPatientPageOffset({
              offsetFrom: "patientMenuTabs",
            })})`}
          >
            {children}
          </Box>
        ) : null;
      })}
    </>
  );
}
