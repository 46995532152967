export const SHADOW_DP1 =
  "0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.12),0px 0px 2px 0px rgba(0, 0, 0, 0.14)";
export const SHADOW_DP2 =
  "0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.12),0px 2px 4px 0px rgba(0, 0, 0, 0.14)";
export const SHADOW_DP3 = "0px 4px 10px 0px rgba(0, 0, 0, 0.1)";
export const SHADOW_DP4 =
  "0px 6px 16px 0px rgba(0, 0, 0, 0.12), 0px -1px 8px 0px rgba(0, 0, 0, 0.12)";
export const SHADOW_DP5 = "0px 2px 4px 0px rgba(0, 0, 0, 0.25)";
export const SHADOW_DP6 =
  "0px 0px 1px 0px rgba(66, 71, 76, 0.50), 0px 4px 8px 0px rgba(66, 71, 76, 0.06), 0px 8px 25px 0px #EBEBEB;";
export const SHADOW_DP7 = "0px 1px 8px 0px rgba(0, 0, 0, 0.25)";
export const SHADOW_DP8 = "0px -2px 8px 0px rgba(0, 0, 0, 0.08)";
export const SHADOW_DP9 = "0px 4px 16px 0px rgba(0, 0, 0, 0.15)";
export const SHADOW_DP10 =
  "0px 0px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.2)";
export const SHADOW_DP_11 =
  "0px 2px 6px 0px rgba(33, 33, 33, 0.12), 0px 0px 2px 0px rgba(33, 33, 33, 0.16)";

export const SHADOW_DROP_SMALL = "0px 4px 10px rgba(119, 85, 119, 0.2)";

export const SHADOW_BUTTON =
  "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)";

export const SHADOW_BUTTON_HOVER =
  "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)";

export const shadow2dp =
  "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2)";

export const shadow3dp =
  "0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.4)";
export const shadow11dp =
  "0px 11px 15px 0px rgba(0, 0, 0, 0.20), 0px 9px 46px 0px rgba(0, 0, 0, 0.12), 0px 24px 38px 0px rgba(0, 0, 0, 0.14)";

export const shadow1 = shadow2dp;
