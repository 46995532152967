import MuiMenu, { MenuProps } from "@mui/material/Menu";
import { handleKeyDown } from "core/model/accessibility/keyboardActions";

export const Menu = ({ children, onClick, onClose, ...props }: MenuProps) => {
  return (
    <MuiMenu
      {...props}
      tabIndex={0}
      autoFocus={true}
      onClick={onClick}
      onClose={onClose}
      TransitionProps={{
        onKeyDown: handleKeyDown({
          onCancel: (event) => {
            onClose?.(event, "escapeKeyDown");
          },
          onConfirm: (event) => {
            onClick?.(
              event as unknown as React.MouseEvent<HTMLDivElement, MouseEvent>,
            );
          },
        }),
      }}
    >
      {children}
    </MuiMenu>
  );
};
