import { useGetCareprovider } from "apollo/hooks/queries";
import {
  ACCOUNT_TYPE_STAFF,
  QUERY_PROGRESS_PENDING,
  TRACK_EVENTS,
} from "core/consts";
import { AppType, CareproviderRoles } from "core/types";
import { SpinnerPage } from "ds_legacy/components/Spinner";
import LoginPage from "dsl/organisms/LoginPage";
import { useTracking } from "react-tracking";
import { ConnectedProviderJWT } from "reduxentities/selectors";

function GetProviderAndRedirect({
  app,
  careproviderId,
  noParams = false,
  RedirectTo,
  useDefault = false,
}: {
  RedirectTo: ToType;
  app: AppType;
  careproviderId: number;
  noParams?: boolean;
  useDefault?: boolean;
}) {
  const { trackEvent } = useTracking();

  const [queryProgress, careprovider] = useGetCareprovider({
    id: careproviderId,
  });

  if (queryProgress === QUERY_PROGRESS_PENDING) {
    return <SpinnerPage id="careprovider-auth-get-careprovider-jwt" />;
  }

  // if there is an error retrieving the careprovider
  // since the id was validated before we can just
  // redirect to the login page instead of showing
  // an error
  if (!careprovider) {
    trackEvent({ name: TRACK_EVENTS.CAREPROVIDER_AUTH_JWT });
    return <LoginPage careproviderToLog={careproviderId} app={app} />;
  }
  return (
    <RedirectTo
      careproviderId={careproviderId}
      useDefault={useDefault}
      noParams={noParams}
    />
  );
}

export default function CareproviderAuthPage({
  app,
  careproviderId: careproviderToLog,
  RedirectTo,
}: {
  RedirectTo: ToType;
  app: AppType;
  careproviderId: number | undefined;
}) {
  return (
    // #TODO: typing of children + remove recompose
    <ConnectedProviderJWT>
      {({
        account,
        careproviderChanged,
        careproviderId,
        roles,
        token,
      }: AnyObject) => {
        if (account?.account_type === ACCOUNT_TYPE_STAFF) {
          const idForStaff =
            careproviderToLog ||
            (roles && roles.length > 0 && roles[0].careprovider.id) ||
            1;

          if (idForStaff !== careproviderId) careproviderChanged(idForStaff);

          return (
            <GetProviderAndRedirect
              app={app}
              careproviderId={idForStaff}
              RedirectTo={RedirectTo}
            />
          );
        }

        // if there is no careproviderId, token or roles
        // in the redux store, redirect to the login page
        if (!careproviderId || !token || !roles)
          return <LoginPage careproviderToLog={careproviderToLog} app={app} />;

        // if there is no careproviderId specified in the url
        // and there is a careprovider in the redux store
        // just use that one
        if (careproviderId && !careproviderToLog)
          return (
            <GetProviderAndRedirect
              app={app}
              careproviderId={careproviderId}
              RedirectTo={RedirectTo}
            />
          );

        // if there is a careproviderId specified in the url
        // check if it exists in the roles of the logged
        // account. if there is and if it matches the logged
        // careprovider in the redux store, just use that one
        // if the id is different, fire a token change with
        // the new id supplied
        if (careproviderToLog && roles) {
          const careproviderIsInRoles = roles.some(
            (role: CareproviderRoles) =>
              role.careprovider && role.careprovider.id == careproviderToLog,
          );
          if (careproviderIsInRoles) {
            if (careproviderToLog !== careproviderId)
              careproviderChanged(careproviderToLog);

            return (
              <GetProviderAndRedirect
                app={app}
                careproviderId={careproviderToLog}
                RedirectTo={RedirectTo}
              />
            );
          }
        }

        // default
        return (
          <GetProviderAndRedirect
            app={app}
            careproviderId={careproviderId}
            RedirectTo={RedirectTo}
            useDefault
          />
        );
      }}
    </ConnectedProviderJWT>
  );
}
