import FormHelperText from "@mui/material/FormHelperText";
import { useEnvContext } from "context/EnvContext";
import { APP_CLINIC } from "core/consts";
import { getPatientName } from "core/model/patients";
import { BarthelIndex, Patient, RehabIndex } from "core/types";
import NewDatePicker from "ds_legacy/components/DatePickerInputField";
import { VerticalLayout } from "ds_legacy/materials/layouts";
import { dp, margin } from "ds_legacy/materials/metrics";
import { FONT_WEIGHT_BOLD, FONT_WEIGHT_REGULAR } from "ds_legacy/materials/typography";
import { useAuctionRequestContext } from "dsl/atoms/Contexts";
import PrintButton from "dsl/atoms/PrintButton";
import {
  useCareproviderNavigationHandlers,
  useCareseekerNavigationHandlers,
} from "dsl/hooks";
import { ActionBanner } from "dsl/organisms/Infos/shared";
import { CSSProperties } from "react";
import { useTranslations } from "translations";
import Translations from "translations/types";
import { BarthelSummaryTitle, ReturnBarWrapper } from "./styles";

export type EntryType = {
  date: number;
  deleted?: boolean;
  form: AnyObject;
};

export const sumValues = (formValue: AnyObject) =>
  Object.values(formValue)
    .filter((v) => typeof v === "number")
    .reduce((currentValue, newValue) => currentValue + newValue, 0);

export const validateIndexForm = (
  formValue: Array<BarthelIndex | RehabIndex>,
  earlyRehab?: boolean,
): boolean =>
  formValue.every(
    (formPart: BarthelIndex | RehabIndex) =>
      formPart.date &&
      (earlyRehab ||
        Object.values(formPart.form).every((value) => value != null)),
  );

export function EntryDate({
  COLUMN_WIDTH,
  dispatch,
  earlyRehab,
  formValid,
  index,
  translations,
  value,
}: {
  COLUMN_WIDTH: any;
  dispatch: (arg: ToType) => void;
  earlyRehab?: boolean;
  formValid: boolean;
  index: number;
  translations: Translations;
  value: EntryType;
}) {
  if (value.deleted) return null;
  return (
    <VerticalLayout width={dp(COLUMN_WIDTH)} style={{ alignItems: "center" }}>
      <div style={{ width: dp(90) }}>
        <NewDatePicker
          elementName="date"
          inputSx={{ margin: margin(0), width: dp(90) }}
          label={translations.acp.barthelIndex.date}
          onChange={(date: number) => {
            const payload = { date, index };
            const args = earlyRehab ? { payload } : { ...payload };
            dispatch({ type: "DATE_CHANGE", ...args });
          }}
          required
          value={value.date === 0 ? null : value.date}
        />
        {!formValid && !value.date && (
          <FormHelperText error>{translations.actions.missing}</FormHelperText>
        )}
      </div>
    </VerticalLayout>
  );
}

export const ReturnBar = ({
  auctionId,
  noBorder,
  patient,
  wrapperStyles,
}: {
  auctionId?: number;
  noBorder?: boolean;
  patient: Patient;
  wrapperStyles?: CSSProperties;
}) => {
  const translations = useTranslations();
  const { app } = useEnvContext();
  const { auctionRequestId, careproviderId } = useAuctionRequestContext();
  const senderNavigation = useCareseekerNavigationHandlers();
  const providerNavigation = useCareproviderNavigationHandlers();

  const goBack = () => {
    if (app === APP_CLINIC) {
      return senderNavigation.patient.goToProfile({
        patientId: patient.id,
        auctionId: auctionId ?? -1,
      });
    }
    if (!auctionRequestId || !careproviderId) {
      console.error(
        `[DEV-14068] [ReturnBar] tried to access bad request id ${auctionRequestId} or careprovider id ${careproviderId}`,
      );
      return;
    }
    return providerNavigation.goToRequest(
      Number(auctionRequestId),
      Number(careproviderId),
    );
  };

  return (
    <ReturnBarWrapper style={wrapperStyles}>
      <ActionBanner
        action={goBack}
        text={translations.patient.medicalDiagnosis.backToProfile}
        actions={[<PrintButton key="print" />]}
        noBorder={noBorder}
      />
    </ReturnBarWrapper>
  );
};

export const BarthelTitle = ({
  earlyRehab = false,
  patient,
  print,
  translations,
}: {
  earlyRehab?: boolean;
  patient?: Patient;
  print?: boolean;
  translations: Translations;
}) => {
  const patientName = getPatientName(patient);

  return (
    <BarthelSummaryTitle print={print}>
      <span style={{ fontWeight: FONT_WEIGHT_REGULAR }}>
        {earlyRehab
          ? translations.patient.medicalDiagnosis
              .earlyRehabBarthelIndexPlaceholder
          : translations.acp.barthelIndex.dialogTitle}
        :
      </span>
      {patientName ? (
        <>
          <span style={{ fontWeight: FONT_WEIGHT_BOLD }}> {patientName}</span>
          <span style={{ fontWeight: FONT_WEIGHT_REGULAR }}>
            {" "}
            ({patient?.user_id})
          </span>
        </>
      ) : (
        <span
          style={{ fontWeight: FONT_WEIGHT_BOLD }}
        >{` Patient ${patient?.user_id}`}</span>
      )}
    </BarthelSummaryTitle>
  );
};
