import {
  FILE_TABLE_ACTIONS,
  FILE_TABLE_ACTION_DELETE,
  FILE_TABLE_ACTION_DOWNLOAD,
  FILE_TABLE_ACTION_SEE_DETAILS,
  FILE_TABLE_ACTION_SHARE,
  FILE_TABLE_ACTION_SYNC_VERSION,
  TRACK_EVENTS,
} from "core/consts";
import { mimeTypeToExtensionMap } from "core/model/files/config";
import {
  FileHISStateful,
  FileTable,
  FileTableActions,
  Filev2,
  Filev2Stateful,
  TrackEventFn,
} from "core/types";
import {
  AutorenewIcon,
  DeleteIcon,
  FindInPageIcon,
  GetAppIcon,
  SendIcon,
} from "ds/icons";
import {
  ActionMenuOption,
  SetAction,
} from "ds_legacy/components/Tables/GeneralTable/MenuActions";
import { FONT_SIZE_14 } from "ds_legacy/materials/typography";
import { FileFeatureData } from "dsl/ecosystems/PatientFiles/useFilesFeature";
import { isFileHISStateful } from "dsl/organisms/FileTables";
import Translations from "translations/types";

export const getFileListActionOptions = ({
  canAccessPatientData,
  file,
  fileTable,
  isNewVersionAvailable,
  setAction,
  trackEvent,
  translations,
}: {
  canAccessPatientData: boolean;
  file: FileHISStateful | Filev2;
  fileTable: FileTable;
  isNewVersionAvailable?: boolean;
  setAction: SetAction<FileFeatureData>;
  trackEvent: TrackEventFn;
  translations: Translations;
}): ActionMenuOption<FileTableActions>[] => {
  if (isFileHISStateful(file)) {
    return [
      {
        color: "primary",
        id: FILE_TABLE_ACTION_SHARE,
        label: translations.actions.shareFile,
        LeftIcon: SendIcon,
        loading: "na",
        onClick: () => {
          trackEvent({
            name: TRACK_EVENTS.FILE_MENU_MAIN_SHARE_BUTTON_CLICKED,
            section: fileTable,
            file_implementation: "HIS",
          });
          setAction({ type: FILE_TABLE_ACTION_SHARE, data: file });
        },
        size: "small",
        type: "button",
        variant: "outlined",
      },
      {
        id: FILE_TABLE_ACTION_DOWNLOAD,
        disabled: !canAccessPatientData,
        label: translations.actions.download,
        icon: (
          <GetAppIcon style={{ fontSize: FONT_SIZE_14 }} size={FONT_SIZE_14} />
        ),
        onClick: () => {
          trackEvent({
            name: TRACK_EVENTS.FILE_MENU_ITEM_CLICKED,
            item: "download",
            section: fileTable,
            file_implementation: "HIS",
          });
          setAction({ type: FILE_TABLE_ACTION_DOWNLOAD, data: file });
        },
      },
      {
        id: FILE_TABLE_ACTION_DELETE,
        label: translations.actions.delete,
        icon: (
          <DeleteIcon style={{ fontSize: FONT_SIZE_14 }} size={FONT_SIZE_14} />
        ),
        onClick: () => {
          trackEvent({
            name: TRACK_EVENTS.FILE_MENU_ITEM_CLICKED,
            item: "delete",
            section: fileTable,
            file_implementation: "HIS",
          });
          setAction({ type: FILE_TABLE_ACTION_DELETE, data: file });
        },
        disabled: true,
        tooltip:
          translations.fileSection.fileListComponent.validation
            .tooltipHisFileNoDeletion,
      },
      {
        id: FILE_TABLE_ACTION_SEE_DETAILS,
        label: translations.actions.seeDetails,
        icon: (
          <FindInPageIcon
            style={{ fontSize: FONT_SIZE_14 }}
            size={FONT_SIZE_14}
          />
        ),
        onClick: () => {
          trackEvent({
            name: TRACK_EVENTS.FILE_MENU_ITEM_CLICKED,
            item: "detailed_overview",
            section: fileTable,
            file_implementation: "HIS",
          });
          setAction({ type: FILE_TABLE_ACTION_SHARE, data: file });
        },
      },
    ];
  }

  return [
    isNewVersionAvailable
      ? {
          color: "primary",
          id: FILE_TABLE_ACTION_SYNC_VERSION,
          label: translations.fileSection.fileListComponent.reloadButton,
          LeftIcon: AutorenewIcon,
          loading: "na",
          onClick: () => {
            trackEvent({
              name: TRACK_EVENTS.FILE_MENU_MAIN_RELOAD_BUTTON_CLICKED,
              section: fileTable,
              file_implementation: "HIS",
            });
            setAction({
              type: FILE_TABLE_ACTION_SYNC_VERSION,
              data: [
                {
                  document_category_number: file.category,
                  document_file_name:
                    `${file.file_name?.decrypted}${
                      mimeTypeToExtensionMap[
                        file.file_type as keyof typeof mimeTypeToExtensionMap
                      ]
                    }` ?? "",
                  document_id: file.kis_document_id_string ?? "",
                  id: file.kis_document_id_string ?? "",
                  share_mode: file.share_mode,
                  fileV2Id: file.id,
                } as FileHISStateful & { fileV2Id?: Filev2Stateful["id"] },
              ],
            });
          },
          size: "small",
          type: "button",
          variant: "outlined",
        }
      : {
          color: "primary",
          id: FILE_TABLE_ACTION_SHARE,
          label: translations.actions.shareFile,
          LeftIcon: SendIcon,
          loading: "na",
          onClick: () => {
            trackEvent({
              name: TRACK_EVENTS.FILE_MENU_MAIN_SHARE_BUTTON_CLICKED,
              section: fileTable,
              file_implementation: "HIS",
            });
            setAction({ type: FILE_TABLE_ACTION_SHARE, data: file });
          },
          size: "small",
          type: "button",
          variant: "outlined",
        },
    {
      id: FILE_TABLE_ACTION_DOWNLOAD,
      disabled:
        file.file_name?.seald_content === "redacted" || !canAccessPatientData,
      label: translations.actions.download,
      icon: (
        <GetAppIcon style={{ fontSize: FONT_SIZE_14 }} size={FONT_SIZE_14} />
      ),
      onClick: () => {
        trackEvent({
          name: TRACK_EVENTS.FILE_MENU_ITEM_CLICKED,
          item: "download",
          section: fileTable,
          file_implementation: "HIS",
        });
        setAction({ type: FILE_TABLE_ACTION_DOWNLOAD, data: file });
      },
    },
    {
      id: FILE_TABLE_ACTION_DELETE,
      label: translations.actions.delete,
      icon: (
        <DeleteIcon style={{ fontSize: FONT_SIZE_14 }} size={FONT_SIZE_14} />
      ),
      onClick: () => {
        trackEvent({
          name: TRACK_EVENTS.FILE_MENU_ITEM_CLICKED,
          item: "delete",
          section: fileTable,
          file_implementation: "HIS",
        });
        setAction({ type: FILE_TABLE_ACTION_DELETE, data: file });
      },
    },
    {
      id: FILE_TABLE_ACTION_SEE_DETAILS,
      label: translations.actions.seeDetails,
      icon: (
        <FindInPageIcon
          style={{ fontSize: FONT_SIZE_14 }}
          size={FONT_SIZE_14}
        />
      ),
      onClick: () => {
        trackEvent({
          name: TRACK_EVENTS.FILE_MENU_ITEM_CLICKED,
          item: "detailed_overview",
          section: fileTable,
          file_implementation: "HIS",
        });
        setAction({ type: FILE_TABLE_ACTION_SHARE, data: file });
      },
    },
  ];
};

export function getAllowList({
  fileTable,
}: {
  fileTable: FileTable;
}): FileTableActions {
  switch (fileTable) {
    case "sent": {
      return [FILE_TABLE_ACTION_DOWNLOAD, FILE_TABLE_ACTION_DELETE];
    }
    case "available":
    case "uploaded": {
      return FILE_TABLE_ACTIONS;
    }
    case "received":
      return [FILE_TABLE_ACTION_DOWNLOAD];

    default:
      return [];
  }
}
